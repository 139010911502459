export const ITranslationKeysMetrics = {
  eventParameters: 'evetParameters',
  playerData: 'playerData',
  playerOnIceData: 'playerOnIceData',
  relativeToTeam: 'relativeToTeam',
  goaliesData: 'goliesData',
  teamsData: 'teamsData',
  dataGroups: 'dataGroups',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysMetrics =
  (typeof ITranslationKeysMetrics)[keyof typeof ITranslationKeysMetrics];

export const ITranslationKeysBase = {
  password: 'password',
  login: 'login',
  userSuccessfullyLoggedIn: 'userSuccessfullyLoggedIn',
  fillAllInputs: 'fillAllInputs',
  eSports: 'eSports',
  eSportsUrl: 'eSportsUrl',
  bpaSportMarketing: 'bpaSportMarketing',
  bpaUrl: 'bpaUrl',
  goToApp: 'goToApp',
  loadingData: 'loadingData',
  loadData: 'loadData',
  sessionExpired: 'sessionExpired',
  selectAll: 'selectAll',
  removeAll: 'removeAll',
  defaultSelectPlaceholder: 'defaultSelectPlaceholder',
  allTeams: 'allTeams',
  player: 'player',
  goalkeeper: 'goalkeeper',
  team: 'team',
  opponent: 'opponent',
  confirm: 'confirm',
  delete: 'delete',
  from: 'from',
  to: 'to',
  insertTime: 'insertTime',
  select: 'select',
  date: 'date',
  match: 'match',
  defaultTemplate: 'defaultTemplate',
  exportData: 'exportData',
  deleteSelected: 'deleteSelected',
  close: 'close',
  with: 'with',
  without: 'without',
  selectedPlayer: 'selectedPlayer',
  withTeammate: 'withTeammate',
  withoutTeammate: 'withoutTeammate',
  teammateWithout: 'teammateWithout',
  formationAll: 'formationAll',
  formationForwards: 'formationForwards',
  formationDefensemen: 'formationDefensemen',
  opponents: 'opponents',
  playersInChart: 'playersInChart',
  stickGrip: 'stickGrip',
  positiveDeviation: 'positiveDeviation',
  overtime: 'overtime',
  gameCourseExpectedGoals: 'gameCourseExpectedGoals',
  gamePeriod: 'gamePeriod',
  average: 'average',
  inLeague: 'inLeague',
  inSeason: 'inSeason',
  inMatch: 'inMatch',
  print: 'print',
  name: 'name',
  surname: 'surname',
  role: 'role',
  requiredArray: 'requiredArray',
  cancel: 'cancel',
  datasetSavedSuccessfully: 'datasetSavedSuccessfully',
  datasetSaveFailed: 'datasetSaveFailed',
  datasetChangedSuccessfully: 'datasetChangedSuccessfully',
  datasetDeletedSuccessfully: 'datasetDeletedSuccessfully',
  datasetDeleteFailed: 'datasetDeleteFailed',
  saveDataset: 'saveDataset',
  editDataset: 'editDataset',
  deleteDataset: 'deleteDataset',
  newDatasetName: 'newDatasetName',
  rename: 'rename',
  nameYourDataset: 'nameYourDataset',
  saveNewDatasetMetrics: 'saveNewDatasetMetrics',
  selectDatasetToEdit: 'selectDatasetToEdit',
  defensiveZone: 'defensiveZone',
  neutralZone: 'neutralZone',
  offensiveZone: 'offensiveZone',
  pass: 'pass',
  showEvents: 'showEvents',
  displayInGraph: 'displayInGraph',
  movingAverage: 'movingAverage',
  pointer: 'pointer',
  save: 'save',
  combinations: 'combinations',
  others: 'others',
  version: 'version',
  selectSeason: 'selectSeason',
  tab: 'tab',
  turnOff: 'turnOff',
  turnOn: 'turnOn',
  teammates: 'teammates',
  yes: 'yes',
  no: 'no',
  id: 'id',
  fromPass: 'fromPass',
  withoutPass: 'withoutPass',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysBase = (typeof ITranslationKeysBase)[keyof typeof ITranslationKeysBase];

export const ITranslationKeysSeasonParts = {
  base: 'base',
  playoff: 'playoff',
  playout: 'playout',
  relegation: 'relegation',
  preliminary: 'preliminary',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysSeasonParts =
  (typeof ITranslationKeysSeasonParts)[keyof typeof ITranslationKeysSeasonParts];

export const ITranslationKeysCountOfPlayers = {
  evenStrength: 'evenStrength',
  powerPlaysShort: 'powerPlaysShort',
  penaltyKilling: 'penaltyKilling',
  allSituations: 'allSituations',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysCountOfPlayers =
  (typeof ITranslationKeysCountOfPlayers)[keyof typeof ITranslationKeysCountOfPlayers];

export const ITranslationKeysHomeOrAway = {
  homeAndAway: 'homeAndAway',
  home: 'home',
  away: 'away',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysHomeOrAway =
  (typeof ITranslationKeysHomeOrAway)[keyof typeof ITranslationKeysHomeOrAway];

export const ITranslationKeysCSVExports = {
  awayOnes: 'awayOnes',
  homeOnes: 'homeOnes',
  result: 'result',
  playerName: 'playerName',
  goalkeeperName: 'goalkeeperName',
  position: 'position',
  user: 'user',
  value: 'value',
  openLinks: 'openLinks',
  totalLinks: 'totalLinks',
  teamName: 'teamName',
  teamShortName: 'teamShortName',
  hlTotalLoginCount: 'hlTotalLoginCount',
  hlLastLogin: 'hlLastLogin',
  ecTotalLoginCount: 'ecTotalLoginCount',
  ecLastLogin: 'ecLastLogin',
  goalkeepersGamelog: 'goalkeepersGamelog',
  playersGamelog: 'playersGamelog',
  teamsGamelog: 'teamsGamelog',
  formationTable: 'formationTable',
  pairsTable: 'pairsTable',
  playerCombinationsTable: 'playerCombinationsTable',
  playersTable: 'playersTable',
  goalkeepersTable: 'goalkeepersTable',
  appUsers: 'appUsers',
  teamsTable: 'teamsTable',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysCSVExports =
  (typeof ITranslationKeysCSVExports)[keyof typeof ITranslationKeysCSVExports];

export const ITranslationKeysGameState = {
  allGameStates: 'allGameStates',
  leading: 'leading',
  losing: 'losing',
  tied: 'tied',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysGameState =
  (typeof ITranslationKeysGameState)[keyof typeof ITranslationKeysGameState];

export const ITranslationKeysErrors = {
  defaultErrorMessage: 'defaultErrorMessage',
  networkErrorMessage: 'Network Error', //axios
  invalidCredentialsErrorMessage: 'invalidCredentialsErrorMessage',
  defaultLoginErrorMessage: 'defaultLoginErrorMessage',
  noAccessRightsErrorMessage: 'noAccessRightsErrorMessage',
  cantChangePasswordErrorMessage: 'cantChangePasswordErrorMessage',
  cantChangePlayerEmailErrorMessage: 'cantChangePlayerEmailErrorMessage',
  cantDeletePlayerEmailErrorMessage: 'cantDeletePlayerEmailErrorMessage',
  cantChangePlayerLanguageErrorMessage: 'cantChangePlayerLanguageErrorMessage',
  saveDataFailedMessage: 'saveDataFailedMessage',
  areYouSureDeleteUser: 'areYouSureDeleteUser',
  continueWithoutSave: 'continueWithoutSave',
  teamOrMediaNotExistsErrorMessage: 'teamOrMediaNotExistsErrorMessage',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysErrors =
  (typeof ITranslationKeysErrors)[keyof typeof ITranslationKeysErrors];

export const ITranslationKeysNavigation = {
  dashboard: 'dashboard',
  players: 'players', // also at widgets
  formations: 'formations', // also at widgets
  videomaps: 'videomaps',
  goalkeepers: 'goalkeepers', // also at widgets
  games: 'games',
  teams: 'teams',
  accountManagement: 'accountManagement',
  logout: 'logout',
  help: 'help',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysNavigation =
  (typeof ITranslationKeysNavigation)[keyof typeof ITranslationKeysNavigation];

export const ITranslationKeysModal = {
  nextTimeDontShow: 'nextTimeDontShow',
  understand: 'understand',
  closeHelp: 'closeHelp',
  chosenTips: 'chosenTips',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysModal =
  (typeof ITranslationKeysModal)[keyof typeof ITranslationKeysModal];

export const ITranslationKeysWidgets = {
  tabs: 'tabs',
  gamelog: 'gamelog',
  trend: 'trend',
  playerCard: 'playerCard',
  comparePlayers: 'comparePlayers',
  similarityPlayers: 'similarityPlayers',
  pairs: 'pairs',
  playerCombinations: 'playerCombinations',
  shots: 'shots',
  heatmap: 'heatmap',
  passes: 'passes',
  faceoffs: 'faceoffs',
  zoneEntries: 'zoneEntries',
  zoneExits: 'zoneExits',
  shootouts: 'shootouts',
  netZones: 'netZones',
  shotmap: 'shotmap',
  goalkeeperCard: 'goalkeeperCard',
  compareGoalkeepers: 'compareGoalkeepers',
  graphicOverview: 'graphicOverview',
  report: 'report',
  headToHead: 'headToHead',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysWidgets =
  (typeof ITranslationKeysWidgets)[keyof typeof ITranslationKeysWidgets];

export const ITranslationKeysWidgetsDescriptions = {
  tabsPlayersDescription: 'tabsPlayersDescription',
  tabsGoalkeepersDescription: 'tabsGoalkeepersDescription',
  tabsTeamsDescription: 'tabsTeamsDescription',
  gamelogPlayersDescription: 'gamelogPlayersDescription',
  gamelogGoalkeepersDescription: 'gamelogGoalkeepersDescription',
  gamelogTeamsDescription: 'gamelogTeamsDescription',
  trendPlayersDescription: 'trendPlayersDescription',
  trendGoalkeepersDescription: 'trendGoalkeepersDescription',
  trendTeamsDescription: 'trendTeamsDescription',
  playerCardDescription: 'playerCardDescription',
  comparePlayersDescription: 'comparePlayersDescription',
  similarityPlayersDescription: 'similarityPlayersDescription',
  pairsDescription: 'pairsDescription',
  playerCombinationsDescription: 'playerCombinationsDescription',
  shotsDescription: 'shotsDescription',
  heatmapDescription: 'heatmapDescription',
  passesDescription: 'passesDescription',
  faceoffsDescription: 'faceoffsDescription',
  zoneEntriesDescription: 'zoneEntriesDescription',
  zoneExitsDescription: 'zoneExitsDescription',
  shootoutsPlayersDescription: 'shootoutsPlayersDescription',
  shootoutsGoalkeepersDescription: 'shootoutsGoalkeepersDescription',
  netZonesDescription: 'netZonesDescription',
  shotmapDescription: 'shotmapDescription',
  goalkeeperCardDescription: 'goalkeeperCardDescription',
  compareGoalkeepersDescription: 'compareGoalkeepersDescription',
  graphicOverviewDescription: 'graphicOverviewDescription',
  reportDescription: 'reportDescription',
  headToHeadDescription: 'headToHeadDescription',
  playersDescription: 'playersDescription',
  formationsDescription: 'formationsDescription',
  formationsGamesDescription: 'formationsGamesDescription',
  goalkeepersDescription: 'goalkeepersDescription',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysWidgetsDescriptions =
  (typeof ITranslationKeysWidgetsDescriptions)[keyof typeof ITranslationKeysWidgetsDescriptions];

export const ITranslationKeysFilters = {
  moreFilters: 'moreFilters',
  lessFilters: 'lessFilters',
  season: 'season',
  partOfSeason: 'partOfSeason',
  selectTeam: 'selectTeam',
  countOfPlayers: 'countOfPlayers',
  selectOpponent: 'selectOpponent',
  selectPlayer: 'selectPlayer',
  selectGoalkeeper: 'selectGoalkeeper',
  lastPlayedMatches: 'lastPlayedMatches',
  dateFromTo: 'dateFromTo',
  homeOrAway: 'homeOrAway',
  gameState: 'gameState',
  gameTime: 'gameTime',
  minimumTOI: 'minimumTOI',
  chooseFormationType: 'chooseFormationType',
  playerInLines: 'playerInLines',
  insertCount: 'insertCount',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysFilters =
  (typeof ITranslationKeysFilters)[keyof typeof ITranslationKeysFilters];

export const ITranslationKeysPagesWithCards = {
  pleaseSelectTeam: 'pleaseSelectTeam',
  pleaseSelectTeamAndPlayer: 'pleaseSelectTeamAndPlayer',
  pleaseSelectSeason: 'pleaseSelectSeason',
  pleaseSelectPart: 'pleaseSelectPart',
  pleaseSelectOneSeasonAndOnePart: 'pleaseSelectOneSeasonAndOnePart',
  pleaseSelectOnlyOneSeason: 'pleaseSelectOnlyOneSeason',
  pleaseSelectGame: 'pleaseSelectGame',
  pleaseSelectTeamAndGoalkeeper: 'pleaseSelectTeamAndGoalkeeper',
  selectPlayerToCompare: 'selectPlayerToCompare',
  selectGoalkeeperToCompare: 'selectGoalkeeperToCompare',
  youCanLoadData: 'youCanLoadData',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysPagesWithCards =
  (typeof ITranslationKeysPagesWithCards)[keyof typeof ITranslationKeysPagesWithCards];

export const ITranslationKeysTables = {
  selectOwnData: 'selectOwnData',
  dataTemplates: 'dataTemplates',
  filterPositions: 'filterPositions',
  filterTeam: 'filterTeam',
  allPositions: 'allPositions',
  onlyForwards: 'onlyForwards',
  onlyDefensemen: 'onlyDefensemen',
  leaguePercentile: 'leaguePercentile',
  leagueOrder: 'leagueOrder',
  leagueAverage: 'leagueAverage',
  displayTimePeriod: 'displayTimePeriod',
  nGames: 'nGames',
  months: 'months',
  quarter: 'quarter',
  custom: 'custom',
  dataSelectionBy: 'dataSelectionBy',
  playerMatches: 'playerMatches',
  teamMatches: 'teamMatches',
  calendar: 'calendar',
  addNextTimePeriod: 'addNextTimePeriod',
  deleteTimePeriod: 'deleteTimePeriod',
  timePeriodByPlayerMatches: 'timePeriodByPlayerMatches',
  timePeriodByTeamMatches: 'timePeriodByTeamMatches',
  timePeriodByCalendar: 'timePeriodByCalendar',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysTables =
  (typeof ITranslationKeysTables)[keyof typeof ITranslationKeysTables];

export const ITranslationKeysPlayerInfo = {
  born: 'born',
  age: 'age',
  height: 'height',
  weight: 'weight',
  stick: 'stick',
  years: 'years',
  leftStick: 'leftStick',
  rightStick: 'rightStick',
  leftAndRightStick: 'leftAndRightStick',
  notAvailable: 'notAvailable',
  compareWithOtherGoalkeeper: 'compareWithOtherGoalkeeper',
  downloadGoalkeeperCard: 'downloadGoalkeeperCard',
  insufficientMinutes: 'insufficientMinutes',
  compareWithOtherPlayer: 'compareWithOtherPlayer',
  downloadPlayerCard: 'downloadPlayerCard',
  attack: 'attack',
  defend: 'defend',
  transition: 'transition',
  powerPlays: 'powerPlays',
  goals: 'goals',
  expectedGoals: 'expectedGoals',
  productivity: 'productivity',
  shotAssists: 'shotAssists',
  expectedGoalsFromAssists: 'expectedGoalsFromAssists',
  influenceOnScoredTeamGoals: 'influenceOnScoredTeamGoals',
  influenceOnExpectedTeamGoals: 'influenceOnExpectedTeamGoals',
  enemyBlocksOrShots: 'enemyBlocksOrShots',
  avoidedEntryIntoZone: 'avoidedEntryIntoZone',
  bluelineDefending: 'bluelineDefending',
  zoneExitDenials: 'zoneExitDenials',
  influenceOnSuccessOfFightsAfterDumpins: 'influenceOnSuccessOfFightsAfterDumpins',
  influenceOnRecivedGoalsOfTeam: 'influenceOnRecivedGoalsOfTeam',
  influenceOnExpectedGoalsOfEnemy: 'influenceOnExpectedGoalsOfEnemy',
  controlledZoneEntries: 'controlledZoneEntries',
  controlledZoneExits: 'controlledZoneExits',
  preferenceOfControlledEntries: 'preferenceOfControlledEntries',
  zoneEntryPasses: 'zoneEntryPasses',
  dumpinsWithFight: 'dumpinsWithFight',
  transitionsFromDefensiveToOffensiveZone: 'transitionsFromDefensiveToOffensiveZone',
  stretchPassZoneExits: 'stretchPassZoneExits',
  slotShotAssists: 'slotShotAssists',
  dumpouts: 'dumpouts',
  defenderShortcut: 'defenderShortcut',
  attackerShortcut: 'attackerShortcut',
  goalkeeperShortcut: 'goalkeeperShortcut',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysPlayerInfo =
  (typeof ITranslationKeysPlayerInfo)[keyof typeof ITranslationKeysPlayerInfo];

export const ITranslationKeysGames = {
  overtimeShortcut: 'overtimeShortcut',
  shootingShortcut: 'shootingShortcut',
  danger: 'danger',
  high: 'high',
  medium: 'medium',
  low: 'low',
  score: 'score',
  lineup: 'lineup',
  teamShots: 'teamShots',
  shotsAgainst: 'shotsAgainst',
  negative: 'negative',
  positive: 'positive',
  shotsHeatmap: 'shotsHeatmap',
  winExpectation: 'winExpectation',
  defenseFirstLetter: 'defenseFirstLetter',
  forwardFirstLetter: 'forwardFirstLetter',
  stickLetter: 'stickLetter',
  leftLetter: 'leftLetter',
  rightLetter: 'rightLetter',
  allShotAttemptsFromSlot: 'allShotAttemptsFromSlot',
  allPlayers: 'allPlayers',
  shotsOnGoal: 'shotsOnGoal',
  playGoals: 'playGoals',
  playAllGoals: 'playAllGoals',
  gameReport: 'gameReport',
  statsInGame5on5: 'statsInGame5on5',
  statsInPowerPlay: 'statsInPowerPlay',
  showAllPlayers: 'showAllPlayers',
  noGamesToDisplay: 'noGamesToDisplay',
  shootingsSuperiority: 'shootingsSuperiority',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysGames =
  (typeof ITranslationKeysGames)[keyof typeof ITranslationKeysGames];

export const ITranslationKeysGoalkeeperInfo = {
  seasonStatistics: 'seasonStatistics',
  successOfInterventions: 'successOfInterventions',
  receivedGoals: 'receivedGoals',
  actualForm: 'actualForm',
  goalsSavedAboveExpectation: 'goalsSavedAboveExpectation',
  stolenGames: 'stolenGames',
  shutouts: 'shutouts',
  goalieReliability: 'goalieReliability',
  controlledSaves: 'controlledSaves',
  saves: 'saves',
  opponentsStick: 'opponentsStick',
  controlled: 'controlled',
  uncontrolled: 'uncontrolled',
  screeningPlayer: 'screeningPlayer',
  deflected: 'deflected',
  afterCrossIceShotAssists: 'afterCrossIceShotAssists',
  shootoutAttempts: 'shootoutAttempts',
  moreGoalies: 'moreGoalies',
  noGoalkeeperDataMessage: 'noGoalkeeperDataMessage',
  noDataForSelected: 'noDataForSelected',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysGoalkeeperInfo =
  (typeof ITranslationKeysGoalkeeperInfo)[keyof typeof ITranslationKeysGoalkeeperInfo];

export const ITranslationKeysAccountManagement = {
  changeOfPassword: 'changeOfPassword',
  hokejPlayerManagement: 'hokejPlayerManagement',
  downloadsManagement: 'downloadsManagement',
  newPassword: 'newPassword',
  newPasswordAgain: 'newPasswordAgain',
  changePassword: 'changePassword',
  downloadVideo: 'downloadVideo',
  deleteVideo: 'deleteVideo',
  multipleFiles: 'multipleFiles',
  category: 'category',
  match: 'match',
  time: 'time',
  downloadAll: 'donwloadAll',
  deleteAll: 'deleteAll',
  previous: 'previous',
  next: 'next',
  enterEmail: 'enterEmail',
  sentClips: 'sentClips',
  playerPosition: 'playerPosition',
  email: 'email',
  videocoachClips: 'videocoachClips',
  passwordsDontMatch: 'passwordsDontMatch',
  passwordTooShort: 'passwordTooShort',
  passwordChanged: 'passwordChanged',
  passwordChangedLoginAgain: 'passwordChangedLoginAgain',
  language: 'language',
  emailEdited: 'emailEdited',
  emailDeleted: 'emailDeleted',
  enterValidEmail: 'enterValidEmail',
  languageChanged: 'languageChanged',
  clipDeleted: 'clipDeleted',
  clipDeleteError: 'clipDeleteError',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysAccountManagement =
  (typeof ITranslationKeysAccountManagement)[keyof typeof ITranslationKeysAccountManagement];

export const ITranslationKeysVideomaps = {
  count: 'count',
  shotsDanger: 'shotsDanger',
  entryDanger: 'entryDanger',
  exitDanger: 'exitDanger',
  last5Games: 'last5Games',
  withTeammate: 'withTeammate',
  filterBy: 'filterBy',
  play: 'play',
  saveMap: 'saveMap',
  saveMaps: 'saveMaps',
  hide: 'hide',
  total: 'total',
  totalValuesPerTeam: 'totalValuesPerTeam',
  left: 'left',
  center: 'center',
  right: 'right',
  playSelectedMatches: 'playSelectedMatches',
  selectAllGames: 'selectAllGames',
  deselectAllGames: 'deselectAllGames',
  allShotAttempts: 'allShotAttempts',
  onNet: 'onNet',
  inSlot: 'inSlot',
  slot: 'slot',
  innerSlot: 'innerSlot',
  oneTimer: 'oneTimer',
  rebound: 'rebound',
  rebounds: 'rebounds',
  forecheck: 'forecheck',
  rush: 'rush',
  cycle: 'cycle',
  oddManRush: 'oddManRush',
  fromFaceoff: 'fromFaceoff',
  whoShoots: 'whoShoots',
  selectAtLeastTwoPlayers: 'selectAtLeastTwoPlayers',
  shotCategory: 'shotCategory',
  shotLocation: 'shotLocation',
  shotTypes: 'shotTypes',
  attackType: 'attackType',
  timeRecalculatedValues: 'timeRecalculatedValues',
  equityAndDerivedValues: 'equityAndDerivedValues',
  relativeToTeam: 'relativeToTeam',
  trajectories: 'trajectories',
  showAllTrajectories: 'showAllTrajectories',
  whoPasses: 'whoPasses',
  passCategory: 'passCategory',
  shotAfterPassLocation: 'shotAfterPassLocation',
  passTypes: 'passTypes',
  behindTheNet: 'behindTheNet',
  crossIce: 'crossIce',
  passOneTimer: 'passOneTimer',
  compareWithLeague: 'compareWithLeague',
  shotsProduction: 'shotsProduction',
  useOfOpportunities: 'useOfOpportunities',
  withoutComparison: 'withoutComparison',
  loadHeatmap: 'loadHeatmap',
  onlyOnePlayerRequired: 'onlyOnePlayerRequired',
  faceoffResult: 'faceoffResult',
  faceoffsFollowedBy: 'faceoffsFollowedBy',
  won: 'won',
  lost: 'lost',
  shotAttempt: 'shotAttempt',
  shotFromSlot: 'shotFromSlot',
  goal: 'goal',
  allFaceoffs: 'allFaceoffs',
  leftSideFaceoffs: 'leftSideFaceoffs',
  rightSideFaceoffs: 'rightSideFaceoffs',
  puckGainType: 'puckGainType',
  entryType: 'entryType',
  exitType: 'exitType',
  enterExitLocation: 'enterExitLocation',
  captureOrBlock: 'captureOrBlock',
  inFight: 'inFight',
  carryIn: 'carryIn',
  withPass: 'withPass',
  carryOut: 'carryOut',
  passBehindRedLine: 'passBehindRedLine',
  middle: 'middle',
  entryExitType: 'entryExitType',
  puckGain: 'puckGain',
  dumpins: 'dumpins',
  shifts: 'shifts',
  dumpout: 'dumpout',
  successfully: 'successfully',
  unsuccessfully: 'unsuccessfully',
  offensiveZoneGain: 'offensiveZoneGain',
  losingPuckInMiddleZone: 'losingPuckInMiddleZone',
  entryCategory: 'entryCategory',
  bySuccess: 'bySuccess',
  finish: 'finish',
  whoExitsFromDefensiveZone: 'whoExitsFromDefensiveZone',
  whoEntersToOffensiveZone: 'whoEntersToOffensiveZone',
  exitCategory: 'exitCategory',
  exitZoneResult: 'exitZoneResult',
  dumpoutResult: 'dumpoutResult',
  dumpinResult: 'dumpinResult',
  dumpoutDanger: 'dumpoutDanger',
  fightForPuck: 'fightForPuck',
  puckGainOfTheAttackingTeam: 'puckGainOfTheAttackingTeam',
  puckGainOfTheDefendingTeam: 'puckGainOfTheDefendingTeam',
  teamRanking: 'teamRanking',
  telhRanking: 'telhRanking',
  blocked: 'blocked',
  missedShots: 'missedShots',
  posts: 'posts',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysVideomaps =
  (typeof ITranslationKeysVideomaps)[keyof typeof ITranslationKeysVideomaps];

export const ITranslationKeysShootout = {
  allAttempts: 'allAttempts',
  shot: 'shot',
  feint: 'feint',
  forehand: 'forehand',
  backhand: 'backhand',
  sideOfEnding: 'sideOfEnding',
  typeOfEnding: 'typeOfEnding',
  resultOfEnding: 'resultOfEnding',
  goalkeepersShootouts: 'goalkeepersShootouts',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysShootout =
  (typeof ITranslationKeysShootout)[keyof typeof ITranslationKeysShootout];

export const ITranslationKeysAllWordNominativ = {
  all: 'all',
  all_male: 'all_male',
  all_female: 'all_female',
  all_one: 'all_one',
  all_male_one: 'all_male_one',
  all_female_one: 'all_female_one',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysAllWordNominativ =
  (typeof ITranslationKeysAllWordNominativ)[keyof typeof ITranslationKeysAllWordNominativ];

export const ITranslationKeysPeriods = {
  period: 'period',
  periodWithOrder: 'periodWithOrder',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysPeriods =
  (typeof ITranslationKeysPeriods)[keyof typeof ITranslationKeysPeriods];

export const ITranslationKeysNetZones = {
  ur: 'ur',
  ce: 'ce',
  ul: 'ul',
  br: 'br',
  fh: 'fh',
  bl: 'bl',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysNetZones =
  (typeof ITranslationKeysNetZones)[keyof typeof ITranslationKeysNetZones];

export const ITranslationKeysVideoCenter = {
  downloadSelectedVideos: 'downloadSelectedVideos',
  videoCenter: 'videoCenter',
  cutBefore: 'cutBefore',
  cutAfter: 'cutAfter',
  videoTime: 'videoTime',
  videocoachNotes: 'videocoachNotes',
  clipName: 'clipName',
  actionRating: 'actionRating',
  situationNote: 'situationNote',
  noteReceivers: 'noteReceivers',
  forwards: 'forwards',
  defensemen: 'defensemen',
  sendToPlayers: 'sendToPlayers',
  downloadingVideos: 'downloadingVideos',
  downloadingVideosSuccesful: 'downloadingVideosSuccesful',
  downloadingVideosError: 'downloadingVideosError',
  clipNameEmpty: 'clipNameEmpty',
  zeroPlayersSelected: 'zeroPlayersSelected',
  noteSend: 'noteSend',
  noteNotSend: 'noteNotSend',
  navigateToVideomap: 'navigateToVideomap',
  videoLinks: 'videoLinks',
  videoLoadFailed: 'videoLoadFailed',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysVideoCenter =
  (typeof ITranslationKeysVideoCenter)[keyof typeof ITranslationKeysVideoCenter];

export const ITranslationKeysTopPlayers = {
  topPlayers: 'topPlayers',
  topPlayers_one: 'topPlayers_one',
  topPlayers_two: 'topPlayers_two',
  topPlayers_few: 'topPlayers_few',
  topPlayers_other: 'topPlayers_other',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysTopPlayers =
  (typeof ITranslationKeysTopPlayers)[keyof typeof ITranslationKeysTopPlayers];

export const ITranslationKeysTracking = {
  backToApp: 'backToApp',
  analyticsOverview: 'analyticsOverview',
  users: 'users',
  logins: 'logins',
  eventLog: 'eventLog',
  admin: 'admin',
  media: 'media',
  user: 'user',
  client: 'client',
  userRole: 'userRole',
  analyticsType: 'analyticsType',
  interval: 'interval',
  displayData: 'displayData',
  activeUser: 'activeUser',
  teamMedia: 'teamMedia',
  pleaseSelectTeamOrMedia: 'pleaseSelectTeamOrMedia',
  visits: 'visits',
  uniqueUsers: 'uniqueUsers',
  openScreensCount: 'openScreensCount',
  screens: 'screens',
  allUsers: 'allUsers',
  pickUser: 'pickUser',
  roleChanged: 'roleChanged',
  videocoachChanged: 'videocoachChanged',
  removeVideocoachNote: 'removeVideocoachNote',
  userActivated: 'userActivated',
  userDeactivated: 'userDeactivated',
  changeInProgress: 'changeInProgress',
  openedPage: 'openedPage',
  event: 'event',
  lastLogin: 'lastLogin',
  loginCountTotalAndLast30Days: 'loginCountTotalAndLast30Days',
  loginList: 'loginList',
  registeredPlayers: 'registeredPlayers',
  sentLinks: 'sentLinks',
  clickthroughRate: 'clickthroughRate',
  playersWithAttendance: 'playersWithAttendance',
  totalPlayersWithSavedClips: 'totalPlayersWithSavedClips',
  totalNotesSent: 'totalNotesSent',
  videocoachNotesSent: 'videocoachNotesSent',
  numberOfClipsInPlaylist: 'numberOfClipsInPlaylist',
  searchPlayer: 'searchPlayer',
  teamSelection: 'teamSelection',
  attendance: 'attendance',
  dataByTimePeriod: 'dataByTimePeriod',
  higherThan: 'higherThan',
  equalTo: 'equalTo',
  doesNotMatter: 'doesNotMatter',
  tracking: 'tracking',
  overview: 'overview',
  inputSearchedEmail: 'inputSearchedEmail',
  downloadUsersList: 'downloadUsersList',
  dateAndTime: 'dateAndTime',
  ipAdress: 'ipAdress',
  eventType: 'eventType',
  allEvents: 'allEvents',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysTracking =
  (typeof ITranslationKeysTracking)[keyof typeof ITranslationKeysTracking];

export const ITranslationKeysCms = {
  helpManagement: 'helpManagement',
  metricsManagement: 'metricsManagement',
  templatesManagement: 'templatesManagement',
  definedTemplatesManagement: 'definedTemplatesManagement',
  userManagement: 'userManagement',
  notFilledOut: 'notFilledOut',
  addUser: 'addUser',
  userAdded: 'userAdded',
  helpContentEditation: 'helpContentEditation',
  editFunctions: 'editFunctions',
  editTips: 'editTips',
  editNews: 'editNews',
  editOpeningText: 'editOpeningText',
  caption: 'caption',
  addAnotherItem: 'addAnotherItem',
  textEditation: 'textEditation',
  itemEditation: 'itemEditation',
  sectionEditation: 'sectionEditation',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysCms = (typeof ITranslationKeysCms)[keyof typeof ITranslationKeysCms];

export const ITranslationKeysEcContent = {
  formationsCompare: 'formationsCompare',
  iceTime: 'iceTime',
  momentum: 'momentum',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysEcContent =
  (typeof ITranslationKeysEcContent)[keyof typeof ITranslationKeysEcContent];

export const ITranslationKeysBugReport = {
  reportBug: 'reportBug',
  metricsInaccuracy: 'metricsInaccuracy',
  featureNotWorking: 'featureNotWorking',
  otherProblem: 'otherProblem',
  describeProblem: 'describeProblem',
  send: 'send',
  wrongTime: 'wrongTime',
  wrongPlayer: 'wrongPlayer',
  wrongEvent: 'wrongEvent',
  reportSent: 'reportSent',
  reportSentError: 'reportSentError',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeysBugReport =
  (typeof ITranslationKeysBugReport)[keyof typeof ITranslationKeysBugReport];

export const ITranslationKeys = {
  ...ITranslationKeysMetrics,
  ...ITranslationKeysBase,
  ...ITranslationKeysSeasonParts,
  ...ITranslationKeysCountOfPlayers,
  ...ITranslationKeysHomeOrAway,
  ...ITranslationKeysGameState,
  ...ITranslationKeysErrors,
  ...ITranslationKeysNavigation,
  ...ITranslationKeysModal,
  ...ITranslationKeysWidgets,
  ...ITranslationKeysWidgetsDescriptions,
  ...ITranslationKeysFilters,
  ...ITranslationKeysPagesWithCards,
  ...ITranslationKeysTables,
  ...ITranslationKeysPlayerInfo,
  ...ITranslationKeysGames,
  ...ITranslationKeysGoalkeeperInfo,
  ...ITranslationKeysAccountManagement,
  ...ITranslationKeysVideomaps,
  ...ITranslationKeysShootout,
  ...ITranslationKeysAllWordNominativ,
  ...ITranslationKeysPeriods,
  ...ITranslationKeysNetZones,
  ...ITranslationKeysVideoCenter,
  ...ITranslationKeysTopPlayers,
  ...ITranslationKeysTracking,
  ...ITranslationKeysCms,
  ...ITranslationKeysEcContent,
  ...ITranslationKeysCSVExports,
  ...ITranslationKeysBugReport,
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ITranslationKeys =
  | ITranslationKeysMetrics
  | ITranslationKeysBase
  | ITranslationKeysSeasonParts
  | ITranslationKeysCountOfPlayers
  | ITranslationKeysHomeOrAway
  | ITranslationKeysGameState
  | ITranslationKeysErrors
  | ITranslationKeysNavigation
  | ITranslationKeysModal
  | ITranslationKeysWidgets
  | ITranslationKeysWidgetsDescriptions
  | ITranslationKeysFilters
  | ITranslationKeysPagesWithCards
  | ITranslationKeysTables
  | ITranslationKeysPlayerInfo
  | ITranslationKeysGames
  | ITranslationKeysGoalkeeperInfo
  | ITranslationKeysAccountManagement
  | ITranslationKeysVideomaps
  | ITranslationKeysShootout
  | ITranslationKeysAllWordNominativ
  | ITranslationKeysPeriods
  | ITranslationKeysNetZones
  | ITranslationKeysVideoCenter
  | ITranslationKeysTopPlayers
  | ITranslationKeysTracking
  | ITranslationKeysCms
  | ITranslationKeysEcContent
  | ITranslationKeysCSVExports
  | ITranslationKeysBugReport;

export type ITypeForTranslation = Record<ITranslationKeys, string>;
