import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IMetricVideoLinkKeys, IVideoCenterState, IVideoEdit, IVideoRecord } from '../../types';

const initialState: IVideoCenterState = {
  categoryKey: '',
  open: false,
  videoRecord: {},
  edits: [],
  isGoalkeeper: false,
  openFromMetric: undefined,
  metricVideoLinkId: undefined,
};

export const videoCenterSlice = createSlice({
  name: 'videoCenter',
  initialState,
  reducers: {
    openVideoCenter: (_, action: PayloadAction<Omit<IVideoCenterState, 'edits'>>) => {
      return {
        ...action.payload,
        edits: [],
        openFromMetric: undefined,
        metricVideoLinkId: undefined,
      };
    },
    openVideoCenterMetrics: (state, action: PayloadAction<boolean>) => {
      state.openFromMetric = action.payload;
    },
    closeVideoCenter: () => {
      return initialState;
    },
    setVideoRecord: (state, action: PayloadAction<IVideoRecord>) => {
      state.videoRecord = action.payload;
    },
    setMetricVideoLinkId: (state, action: PayloadAction<IMetricVideoLinkKeys | undefined>) => {
      state.metricVideoLinkId = action.payload;
    },
    setVideoEdit: (state, action: PayloadAction<IVideoEdit>) => {
      const video = state.videoRecord[action.payload.videoUuid];
      const stateEdits = state.edits.find(item => item.videoUuid === action.payload.videoUuid);

      if (
        video &&
        video.cutAfter === action.payload.cutAfter &&
        video.cutBefore === action.payload.cutBefore &&
        video.videoTime === action.payload.videoTime
      ) {
        if (stateEdits) {
          state.edits = state.edits.filter(edit => edit.videoUuid !== action.payload.videoUuid);
        }
      } else {
        if (stateEdits) {
          state.edits = [
            ...state.edits.filter(edit => edit.videoUuid !== action.payload.videoUuid),
            action.payload,
          ];
        } else {
          state.edits.push(action.payload);
        }
      }
    },
    resetVideoEditById: (state, action: PayloadAction<string>) => {
      const stateEdits = state.edits.find(item => item.videoUuid === action.payload);

      if (stateEdits) {
        state.edits = state.edits.filter(edit => edit.videoUuid !== action.payload);
      }
    },
  },
});

export const {
  openVideoCenter,
  openVideoCenterMetrics,
  closeVideoCenter,
  setVideoRecord,
  setMetricVideoLinkId,
  setVideoEdit,
  resetVideoEditById,
} = videoCenterSlice.actions;

export const videoCenterReducer = videoCenterSlice.reducer;
