import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { shiftEventsOptions } from '../../../../constants';
import {
  selectMainFilter,
  selectTeams,
  selectVideoShifts,
  setSelectedShiftEvents,
} from '../../../../features';
import { useMultiselect } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IShiftVideoRecord } from '../../../../types';
import { createClassNames, prepareDownloadVideoShift } from '../../../../utils';
import { Button } from '../../../Button';
import { CheckboxButton } from '../../../CheckboxButton';
import { Loading } from '../../../Loading';
import { useOnDownloadVideos } from '../../hooks';
import { ISharedVideoCenterSelectListProps } from '../../types';
import { VideoShiftsCheckboxButton } from '../VideoShiftsCheckboxButton';
import './VideoShiftsSelectList.styles.scss';

export type IVideoShiftsSelectListProps<TOption = undefined> =
  ISharedVideoCenterSelectListProps<TOption> & {
    /** Object with videos data. */
    videosRecord: IShiftVideoRecord;
    /** Disables category key label. */
    disableCategoryKey?: boolean;
  };

const classNames = createClassNames('video-shifts-select-list');

/** Renders video center list and allows to multi select videos, select all videos or download videos. */
export const VideoShiftsSelectList = <TOption extends any = undefined>({
  options,
  selected,
  onChange,
  hasSelectAllOption = true,
  color,
  shouldCheckFullBody = false,
  categoryKey,
  videosRecord,
  playerRecord,
  disableCategoryKey = false,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
}: IVideoShiftsSelectListProps<TOption>) => {
  const teams = useAppSelector(selectTeams);
  const { selectedGame } = useAppSelector(selectMainFilter);
  const { selectedShiftEvents, isLoading } = useAppSelector(selectVideoShifts);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { downloading, onDownload } = useOnDownloadVideos();

  const handleDownload = () => {
    const downloadVideos = selectedGame
      ? [
          ...selected.map(option =>
            prepareDownloadVideoShift(
              videosRecord[option.value],
              playerRecord,
              teams.byId,
              categoryKey,
              selectedGame,
            ),
          ),
        ]
      : [];
    onDownload(downloadVideos);
  };

  const { selectedAll, onChangeToggle, onChangeToggleSelectAll } = useMultiselect<TOption>({
    options,
    selected,
    onChange,
    hasSelectAllOption,
  });

  return (
    <div className={classNames()}>
      {!disableCategoryKey && <h2 className={classNames('label')}>{t(categoryKey)}</h2>}
      {hasSelectAllOption && (
        <div>
          <div className={classNames('toolbar-header')}>{t(ITranslationKeys.showEvents)}</div>
          <div className={classNames('toolbar')}>
            <div className={classNames('toolbar__shift-events-filter')}>
              {shiftEventsOptions.map(option => {
                const activeOption = selectedShiftEvents?.value === option.value;

                return (
                  <Button
                    key={option.value}
                    label={option.label}
                    labelTranslationsOptions={option.labelTranslationsOptions}
                    onClick={
                      !activeOption ? () => dispatch(setSelectedShiftEvents(option)) : undefined
                    }
                    variant={activeOption ? 'normal' : 'gray'}
                    primary
                  />
                );
              })}
            </div>
            <div className={classNames('toolbar__all-button')}>
              <CheckboxButton checked={selectedAll} onClick={onChangeToggleSelectAll}>
                <p>{t(ITranslationKeys.selectAll)}</p>
              </CheckboxButton>
            </div>
          </div>
        </div>
      )}
      <div className={classNames('options-box')}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={classNames('options-box__options')}>
            {options.map(option => {
              const video = videosRecord[option.value];

              if (!video || !video.shiftInfo) return null;

              return (
                <VideoShiftsCheckboxButton
                  key={option.value}
                  {...{
                    video,
                    option,
                    selected,
                    playerRecord,
                    onChangeToggle,
                    onPlay,
                    onVideocoachNote,
                    onCroppedTime,
                    color,
                    shouldCheckFullBody,
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
      <Button
        label={ITranslationKeys.downloadSelectedVideos}
        disabled={selected.length === 0 || downloading}
        iconComponent={<DownloadIcon />}
        onClick={handleDownload}
      />
    </div>
  );
};
