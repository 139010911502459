import {
  ITranslationKeys,
  ITranslationKeysAccountManagement,
  ITranslationKeysAllWordNominativ,
  ITranslationKeysBase,
  ITranslationKeysBugReport,
  ITranslationKeysCSVExports,
  ITranslationKeysCms,
  ITranslationKeysCountOfPlayers,
  ITranslationKeysEcContent,
  ITranslationKeysErrors,
  ITranslationKeysFilters,
  ITranslationKeysGameState,
  ITranslationKeysGames,
  ITranslationKeysGoalkeeperInfo,
  ITranslationKeysHomeOrAway,
  ITranslationKeysMetrics,
  ITranslationKeysModal,
  ITranslationKeysNavigation,
  ITranslationKeysNetZones,
  ITranslationKeysPagesWithCards,
  ITranslationKeysPeriods,
  ITranslationKeysPlayerInfo,
  ITranslationKeysSeasonParts,
  ITranslationKeysShootout,
  ITranslationKeysTables,
  ITranslationKeysTopPlayers,
  ITranslationKeysTracking,
  ITranslationKeysVideoCenter,
  ITranslationKeysVideomaps,
  ITranslationKeysWidgets,
  ITranslationKeysWidgetsDescriptions,
  ITypeForTranslation,
} from './types';

const translationMetrics: Pick<ITypeForTranslation, ITranslationKeysMetrics> = {
  [ITranslationKeys.eventParameters]: 'Parametry událostí',
  [ITranslationKeys.playerData]: 'Data hráče',
  [ITranslationKeys.playerOnIceData]: 'Data týmu s hráčem na ledě',
  [ITranslationKeys.relativeToTeam]: 'Relativní ke zbytku týmu',
  [ITranslationKeys.goaliesData]: 'Data brankářů',
  [ITranslationKeys.teamsData]: 'Data týmu',
  [ITranslationKeys.dataGroups]: 'Skupiny dat',
};

const translationBase: Pick<ITypeForTranslation, ITranslationKeysBase> = {
  [ITranslationKeys.password]: 'Heslo',
  [ITranslationKeys.login]: 'Přihlásit se',
  [ITranslationKeys.userSuccessfullyLoggedIn]: 'Uživatel se úspěšně přihlásil.',
  [ITranslationKeys.fillAllInputs]: 'Vyplňte prosím všechna pole.',
  [ITranslationKeys.eSports]: 'eSports.cz (CZ)',
  [ITranslationKeys.eSportsUrl]: 'http://esportsmedia.cz',
  [ITranslationKeys.bpaSportMarketing]: 'BPA sport marketing a.s.',
  [ITranslationKeys.bpaUrl]: 'https://www.bpa.cz',
  [ITranslationKeys.goToApp]: 'Přejít do aplikace',
  [ITranslationKeys.loadingData]: 'Nahrávám data',
  [ITranslationKeys.loadData]: 'Nahrát data',
  [ITranslationKeys.sessionExpired]: 'Platnost relace přihlášení vypršela. Přihlaste se znovu.',
  [ITranslationKeys.selectAll]: 'Vybrat vše',
  [ITranslationKeys.removeAll]: 'Odebrat vše',
  [ITranslationKeys.defaultSelectPlaceholder]: 'Vyberte možnost',
  [ITranslationKeys.allTeams]: 'Všechny týmy',
  [ITranslationKeys.player]: 'Hráč',
  [ITranslationKeys.goalkeeper]: 'Brankář',
  [ITranslationKeys.team]: 'Tým',
  [ITranslationKeys.opponent]: 'Soupeř',
  [ITranslationKeys.confirm]: 'Potvrdit',
  [ITranslationKeys.delete]: 'Smazat',
  [ITranslationKeys.from]: 'Od',
  [ITranslationKeys.to]: 'Do',
  [ITranslationKeys.insertTime]: 'Zadejte čas',
  [ITranslationKeys.select]: 'Vybrat',
  [ITranslationKeys.date]: 'Datum',
  [ITranslationKeys.match]: 'Zápas',
  [ITranslationKeys.defaultTemplate]: 'Výchozí šablona',
  [ITranslationKeys.exportData]: 'Exportovat data',
  [ITranslationKeys.deleteSelected]: 'Smazat výběr',
  [ITranslationKeys.close]: 'Zavřít',
  [ITranslationKeys.with]: 's',
  [ITranslationKeys.without]: 'bez',
  [ITranslationKeys.selectedPlayer]: 'Vybraný hráč',
  [ITranslationKeys.withTeammate]: 'Hráči spolu',
  [ITranslationKeys.withoutTeammate]: 'Hráč zvlášť',
  [ITranslationKeys.teammateWithout]: 'Spoluhráč zvlášť',
  [ITranslationKeys.formationAll]: '5/5 - všichni',
  [ITranslationKeys.formationForwards]: '5/5 - útoky',
  [ITranslationKeys.formationDefensemen]: '5/5 - obrany',
  [ITranslationKeys.opponents]: 'Protihráči',
  [ITranslationKeys.playersInChart]: 'Hráči v tabulce',
  [ITranslationKeys.stickGrip]: 'Držení hole',
  [ITranslationKeys.positiveDeviation]: 'Pozitivní odchylka',
  [ITranslationKeys.overtime]: 'Prodloužení',
  [ITranslationKeys.gameCourseExpectedGoals]: 'Vývoj zápasu: očekávané góly',
  [ITranslationKeys.gamePeriod]: 'Část utkání',
  [ITranslationKeys.average]: 'Průměr',
  [ITranslationKeys.inLeague]: 'V lize',
  [ITranslationKeys.inSeason]: 'V sezóně',
  [ITranslationKeys.inMatch]: 'V zápase',
  [ITranslationKeys.print]: 'Vytisknout',
  [ITranslationKeys.name]: 'Jméno',
  [ITranslationKeys.surname]: 'Příjmení',
  [ITranslationKeys.role]: 'Role',
  [ITranslationKeys.requiredArray]: 'Povinné pole',
  [ITranslationKeys.cancel]: 'Zrušit',
  [ITranslationKeys.datasetSavedSuccessfully]: 'Datová šablona úspěšně uložena.',
  [ITranslationKeys.datasetSaveFailed]: 'Uložení šablony selhalo. Zkuste to prosím znovu.',
  [ITranslationKeys.datasetChangedSuccessfully]: 'Datová šablona úspěšně změněna.',
  [ITranslationKeys.datasetDeletedSuccessfully]: 'Datová šablona úspěšně smazána.',
  [ITranslationKeys.datasetDeleteFailed]: 'Smazání šablony selhalo. Zkuste to prosím znovu.',
  [ITranslationKeys.saveDataset]: 'Uložit datovou šablonu',
  [ITranslationKeys.editDataset]: 'Editovat datovou šablonu',
  [ITranslationKeys.deleteDataset]: 'Smazat datovou šablonu',
  [ITranslationKeys.newDatasetName]: 'Nový název šablony',
  [ITranslationKeys.rename]: 'Přejmenovat',
  [ITranslationKeys.nameYourDataset]: 'Pojmenujte si prosím skupinu vybraných dat',
  [ITranslationKeys.saveNewDatasetMetrics]: 'Uložit skupinu vybraných dat do této šablony',
  [ITranslationKeys.selectDatasetToEdit]: 'Vyberte prosím šablonu, kterou chcete upravit',
  [ITranslationKeys.defensiveZone]: 'Obranné pásmo',
  [ITranslationKeys.neutralZone]: 'Střední pásmo',
  [ITranslationKeys.offensiveZone]: 'Útočné pásmo',
  [ITranslationKeys.pass]: 'Přihrávka',
  [ITranslationKeys.showEvents]: 'Zobrazit události',
  [ITranslationKeys.displayInGraph]: 'Zobrazit v grafu',
  [ITranslationKeys.movingAverage]: 'Klouzavý průměr',
  [ITranslationKeys.pointer]: 'Ukazatel',
  [ITranslationKeys.save]: 'Uložit',
  [ITranslationKeys.combinations]: 'Kombinace',
  [ITranslationKeys.others]: 'Ostatní',
  [ITranslationKeys.version]: 'Verze',
  [ITranslationKeys.selectSeason]: 'Vybrat sezónu',
  [ITranslationKeys.tab]: 'Záložka',
  [ITranslationKeys.turnOff]: 'Vypnout',
  [ITranslationKeys.turnOn]: 'Zapnout',
  [ITranslationKeys.teammates]: 'Spoluhráči',
  [ITranslationKeys.yes]: 'Ano',
  [ITranslationKeys.no]: 'Ne',
  [ITranslationKeys.id]: 'ID',
  [ITranslationKeys.fromPass]: 'Z přihrávky',
  [ITranslationKeys.withoutPass]: 'Bez přihrávky',
};

const translationSeasonParts: Pick<ITypeForTranslation, ITranslationKeysSeasonParts> = {
  [ITranslationKeys.base]: 'Základní část',
  [ITranslationKeys.playoff]: 'Play-off',
  [ITranslationKeys.playout]: 'Play-out',
  [ITranslationKeys.relegation]: 'Baráž',
  [ITranslationKeys.preliminary]: 'Předkolo',
};

const translationCountOfPlayers: Pick<ITypeForTranslation, ITranslationKeysCountOfPlayers> = {
  [ITranslationKeys.evenStrength]: 'Rovnovážný stav',
  [ITranslationKeys.powerPlaysShort]: 'Přesilovky',
  [ITranslationKeys.penaltyKilling]: 'Oslabení',
  [ITranslationKeys.allSituations]: 'Všechny situace',
};

const translationHomeOrAway: Pick<ITypeForTranslation, ITranslationKeysHomeOrAway> = {
  [ITranslationKeys.homeAndAway]: 'Doma i venku',
  [ITranslationKeys.home]: 'Doma',
  [ITranslationKeys.away]: 'Venku',
};

const translationCSVExports: Pick<ITypeForTranslation, ITranslationKeysCSVExports> = {
  [ITranslationKeys.awayOnes]: 'Hosté',
  [ITranslationKeys.homeOnes]: 'Domácí',
  [ITranslationKeys.result]: 'Výsledek',
  [ITranslationKeys.playerName]: 'Jméno hráče',
  [ITranslationKeys.goalkeeperName]: 'Jméno brankáře',
  [ITranslationKeys.position]: 'Pozice',
  [ITranslationKeys.user]: 'Uživatel',
  [ITranslationKeys.value]: 'Hodnota',
  [ITranslationKeys.openLinks]: 'Otevřené odkazy',
  [ITranslationKeys.totalLinks]: 'Celkem odkazy',
  [ITranslationKeys.teamName]: 'Název týmu',
  [ITranslationKeys.teamShortName]: 'Zkratka týmu',
  [ITranslationKeys.hlTotalLoginCount]: 'HL Počet přihlášení celkem + za 30 dní',
  [ITranslationKeys.hlLastLogin]: 'HL Poslední přihlášení',
  [ITranslationKeys.ecTotalLoginCount]: 'EC Počet přihlášení celkem + za 30 dní',
  [ITranslationKeys.ecLastLogin]: 'EC Poslední přihlášení',
  [ITranslationKeys.goalkeepersGamelog]: 'brankáři-gamelog',
  [ITranslationKeys.playersGamelog]: 'hráči-gamelog',
  [ITranslationKeys.teamsGamelog]: 'týmy-gamelog',
  [ITranslationKeys.formationTable]: 'formace-tabulka',
  [ITranslationKeys.pairsTable]: 'dvojice-tabulka',
  [ITranslationKeys.playerCombinationsTable]: 'kombinace-hracu-tabulka',
  [ITranslationKeys.playersTable]: 'hráči-tabulka',
  [ITranslationKeys.goalkeepersTable]: 'brankáři-tabulka',
  [ITranslationKeys.appUsers]: 'uživatelé',
  [ITranslationKeys.teamsTable]: 'týmy-tabulka',
};

const translationGameState: Pick<ITypeForTranslation, ITranslationKeysGameState> = {
  [ITranslationKeys.allGameStates]: 'Všechny stavy',
  [ITranslationKeys.leading]: 'Ve vedení',
  [ITranslationKeys.losing]: 'Při prohrávání',
  [ITranslationKeys.tied]: 'Vyrovnaný',
};

const translationErrors: Pick<ITypeForTranslation, ITranslationKeysErrors> = {
  [ITranslationKeys.defaultErrorMessage]:
    'Při načítání dat došlo k chybě. Kontaktujte nás prosím na e-mailu podpora@esports.cz.',
  [ITranslationKeys.networkErrorMessage]: 'Chyba připojení.',
  [ITranslationKeys.invalidCredentialsErrorMessage]: 'Nesprávné přihlašovací údaje.',
  [ITranslationKeys.defaultLoginErrorMessage]: 'Chyba! Přihlášení se nepodařilo.',
  [ITranslationKeys.noAccessRightsErrorMessage]: 'Pro přihlášení nemáte patřičná oprávnění.',
  [ITranslationKeys.cantChangePasswordErrorMessage]:
    'Změna hesla se nezdařila. Zkuste to prosím znovu.',
  [ITranslationKeys.cantChangePlayerEmailErrorMessage]:
    'Změna emailu uživatele se nezdařila. Zkuste to prosím znovu.',
  [ITranslationKeys.cantDeletePlayerEmailErrorMessage]:
    'Smazání emailu uživatele se nezdařilo. Zkuste to prosím znovu.',
  [ITranslationKeys.cantChangePlayerLanguageErrorMessage]:
    'Změna jazyka uživatele se nezdařila. Zkuste to prosím znovu.',
  [ITranslationKeys.saveDataFailedMessage]:
    'Při uložení dat došlo k chybě. Zkuste to prosím znovu.',
  [ITranslationKeys.areYouSureDeleteUser]:
    'Opravdu si přejete vymazat tohoto uživatele? Tato operace je nevratná.',
  [ITranslationKeys.continueWithoutSave]:
    'Data nebyla uložena. Přejete si i přes to pokračovat? Pokračování vymaže provedné změny.',
  [ITranslationKeys.teamOrMediaNotExistsErrorMessage]:
    'Neexistující tým nebo média. Zkuste to prosím znovu.',
};

const translationNavigation: Pick<ITypeForTranslation, ITranslationKeysNavigation> = {
  [ITranslationKeys.dashboard]: 'Dashboard',
  [ITranslationKeys.players]: 'Hráči',
  [ITranslationKeys.formations]: 'Formace',
  [ITranslationKeys.videomaps]: 'Videomapy',
  [ITranslationKeys.goalkeepers]: 'Brankáři',
  [ITranslationKeys.games]: 'Zápasy',
  [ITranslationKeys.teams]: 'Týmy',
  [ITranslationKeys.accountManagement]: 'Správa účtu',
  [ITranslationKeys.logout]: 'Odhlásit se',
  [ITranslationKeys.help]: 'Nápověda',
};

const translationModal: Pick<ITypeForTranslation, ITranslationKeysModal> = {
  [ITranslationKeys.nextTimeDontShow]: 'Příště již nezobrazovat',
  [ITranslationKeys.understand]: 'Rozumím',
  [ITranslationKeys.closeHelp]: 'Zavřít nápovědu',
  [ITranslationKeys.chosenTips]: 'Vybrané tipy',
};

const translationWidgets: Pick<ITypeForTranslation, ITranslationKeysWidgets> = {
  [ITranslationKeys.tabs]: 'Tabulky',
  [ITranslationKeys.gamelog]: 'Gamelog',
  [ITranslationKeys.trend]: 'Trend',
  [ITranslationKeys.playerCard]: 'Karta hráče',
  [ITranslationKeys.comparePlayers]: 'Porovnání hráčů',
  [ITranslationKeys.similarityPlayers]: 'Shoda hráčů',
  [ITranslationKeys.pairs]: 'Dvojice',
  [ITranslationKeys.playerCombinations]: 'Kombinace hráčů',
  [ITranslationKeys.shots]: 'Střely',
  [ITranslationKeys.heatmap]: 'Heatmapa',
  [ITranslationKeys.passes]: 'Přihrávky',
  [ITranslationKeys.faceoffs]: 'Vhazování',
  [ITranslationKeys.zoneEntries]: 'Vstup do pásma',
  [ITranslationKeys.zoneExits]: 'Výstup z pásma',
  [ITranslationKeys.shootouts]: 'Nájezdy',
  [ITranslationKeys.netZones]: 'Zóny branky',
  [ITranslationKeys.shotmap]: 'Mapa střel',
  [ITranslationKeys.goalkeeperCard]: 'Karta brankáře',
  [ITranslationKeys.compareGoalkeepers]: 'Porovnání brankářů',
  [ITranslationKeys.graphicOverview]: 'Grafický přehled',
  [ITranslationKeys.report]: 'Report',
  [ITranslationKeys.headToHead]: 'Kdo proti komu',
};

const translationWidgetsDescriptions: Pick<
  ITypeForTranslation,
  ITranslationKeysWidgetsDescriptions
> = {
  [ITranslationKeys.tabsPlayersDescription]:
    'Jak si vedou jednotliví hráči ve všech metrikách během sezóny? A jak ve srovnání se všemi hráči ligy?',
  [ITranslationKeys.tabsGoalkeepersDescription]:
    'Jak si vedou jednotliví brankáři během sezóny? A jak jsou na tom ve srovnání s průměrem ligy?',
  [ITranslationKeys.tabsTeamsDescription]:
    'Jak si vedou jednotlivé týmy během sezóny? A jak jsou na tom ve srovnání s ostatními týmy ligy?',
  [ITranslationKeys.gamelogPlayersDescription]:
    'Jaký výkon podával hráč zápas po zápase? V jakých zápasech se mu dařilo a v jakých naopak ne?',
  [ITranslationKeys.gamelogGoalkeepersDescription]:
    'Jaký výkon podával brankář zápas po zápase? V jakých zápasech se mu dařilo a v jakých naopak ne?',
  [ITranslationKeys.gamelogTeamsDescription]:
    'Jaký výkon podával vybraný tým zápas po zápase? V jakých zápasech se mu dařilo a v jakých naopak ne?',
  [ITranslationKeys.trendPlayersDescription]:
    'Jak se vyvíjel hráčův výkon během sezóny? V jakých obdobích byl lepší a kdy naopak horší?',
  [ITranslationKeys.trendGoalkeepersDescription]:
    'Jak se vyvíjela brankářova produkce během sezóny? V jakých obdobích byla vyšší a kdy naopak nižší? ',
  [ITranslationKeys.trendTeamsDescription]:
    'Jak se vyvíjel výkon týmu během sezóny? V jakých obdobích byl lepší a kdy naopak horší?',
  [ITranslationKeys.playerCardDescription]:
    'Jak si hráč vede v nejdůležitějších oblastech hry ve srovnání s ostatními? Jaké jsou jeho silné stránky?',
  [ITranslationKeys.comparePlayersDescription]:
    'Který ze srovnávaných hráčů lépe zvládá Přesilovky? Který naopak Tranzici?',
  [ITranslationKeys.similarityPlayersDescription]:
    'Kteří hráči v lize jsou nejpodobnější vybranému hráči podle vámi zvolených metrik?',
  [ITranslationKeys.pairsDescription]:
    'S kým ve dvojici je vybraný hráč produktivnější než bez něj. U koho je to naopak?',
  [ITranslationKeys.playerCombinationsDescription]:
    'Které kombinace hráčů spolu fungují a které ne? A proti kterým protihráčům jsou lepší či horší?',
  [ITranslationKeys.shotsDescription]:
    'VIDEO: Z jakých lokací a do jakých zón branky týmy či hráči střílí? Z jakých akcí a z jakých typů střel?',
  [ITranslationKeys.heatmapDescription]:
    'Odkud vybraný tým či hráč střílí častěji a proměňuje své příležitosti úspěšněji než průměr ligy?',
  [ITranslationKeys.passesDescription]:
    'VIDEO: Z jakých lokací hráči přihrávají na střely? Z jakých akcí a na jaké typy střel?',
  [ITranslationKeys.faceoffsDescription]:
    'VIDEO: Na jaké straně hřiště a v jakém pásmu jsou hráči úspěšní na vhazování? A proti jakým soupeřům?',
  [ITranslationKeys.zoneEntriesDescription]:
    'VIDEO: Kudy hráči vstupují do útočného pásma? Které vstupy byly zakončeny střelou, šancí či gólem?',
  [ITranslationKeys.zoneExitsDescription]:
    'VIDEO: Kudy hráči vystupují z obranného pásma? Ztratili po výstupu puk nebo byl přechod úspěšný?',
  [ITranslationKeys.shootoutsPlayersDescription]:
    'VIDEO: Jak hráči nejčastěji zakončují samostatné nájezdy? Kličkou? Střelou? A do jaké zóny branky?',
  [ITranslationKeys.shootoutsGoalkeepersDescription]:
    'VIDEO: Jak si vybraný brankář vede v samostatných nájezdech? Jak čelí kličkám? A jak střelám?',
  [ITranslationKeys.netZonesDescription]:
    'Do jaké části branky dostává vybraný brankář nejvíce gólů? Na lapačku? Mezi betony?',
  [ITranslationKeys.shotmapDescription]:
    'VIDEO: Z jakých lokací a do jakých zón branky míří soupeři na brankáře? Z jakých akcí a typů střel?',
  [ITranslationKeys.goalkeeperCardDescription]:
    'Jak si brankář vede v nejdůležitějších oblastech hry ve srovnání s ostatními? Jaké jsou jeho silné stránky?',
  [ITranslationKeys.compareGoalkeepersDescription]:
    'Který ze srovnávaných brankářů podal kvalitnější výkony? V jakých oblastech se výkony brankářů liší?',
  [ITranslationKeys.graphicOverviewDescription]:
    'Který tým v zápase dominoval? Kdy se zápas lámal? Bylo vítězství očekávané, nebo spíše šťastné?',
  [ITranslationKeys.reportDescription]:
    'Jaký výkon podaly týmy v klíčových metrikách ve vybraném zápase? A kteří hráči v nich byli "top"?',
  [ITranslationKeys.headToHeadDescription]:
    'Kteří hráči nastupovali proti kterým hráčům soupeře nejčastěji a s jakým výsledkem?',
  [ITranslationKeys.playersDescription]:
    'Jak si vedli jednotliví hráči ve vybraném zápase? VIDEO: jak vypadala jednotlivá střídání hráčů?',
  [ITranslationKeys.formationsDescription]:
    'Které obranné dvojice, útočné trojice či speciální formace týmu jsou nejlepší? A ve srovnání s ligou?',
  [ITranslationKeys.formationsGamesDescription]:
    'Jak si vedly jednotlivé útočné trojice, obranné dvojice a speciální formace týmu ve vybraném zápase?',
  [ITranslationKeys.goalkeepersDescription]:
    'Jaký výkon podali brankáři ve vybraném zápase ve všech metrikách? A ve srovnání s brankáři soupeře?',
};

const translationFilters: Pick<ITypeForTranslation, ITranslationKeysFilters> = {
  [ITranslationKeys.moreFilters]: 'Více filtrů',
  [ITranslationKeys.lessFilters]: 'Méně filtrů',
  [ITranslationKeys.season]: 'Sezóna',
  [ITranslationKeys.partOfSeason]: 'Část sezóny',
  [ITranslationKeys.selectTeam]: 'Vybrat tým',
  [ITranslationKeys.countOfPlayers]: 'Počet hráčů na ledě',
  [ITranslationKeys.selectOpponent]: 'Vybrat soupeře',
  [ITranslationKeys.selectPlayer]: 'Vyberte hráče',
  [ITranslationKeys.selectGoalkeeper]: 'Vybrat brankáře',
  [ITranslationKeys.lastPlayedMatches]: 'Počet posledních zápasů',
  [ITranslationKeys.dateFromTo]: 'Datum od - do',
  [ITranslationKeys.homeOrAway]: 'Doma / Venku',
  [ITranslationKeys.gameState]: 'Stav utkání',
  [ITranslationKeys.gameTime]: 'Minuty v utkání',
  [ITranslationKeys.minimumTOI]: 'Minimální TOI',
  [ITranslationKeys.chooseFormationType]: 'Vyberte druh formace',
  [ITranslationKeys.playerInLines]: 'Formace s hráčem',
  [ITranslationKeys.insertCount]: 'Zadejte počet',
};

const translationPagesWithCards: Pick<ITypeForTranslation, ITranslationKeysPagesWithCards> = {
  [ITranslationKeys.pleaseSelectTeam]: 'Vyberte prosím tým',
  [ITranslationKeys.pleaseSelectTeamAndPlayer]: 'Vyberte prosím tým a hráče',
  [ITranslationKeys.pleaseSelectSeason]: 'Vyberte prosím sezónu',
  [ITranslationKeys.pleaseSelectPart]: 'Vyberte prosím část sezóny',
  [ITranslationKeys.pleaseSelectOneSeasonAndOnePart]:
    'Vyberte prosím sezónu a následně jednu soutěž',
  [ITranslationKeys.pleaseSelectOnlyOneSeason]: 'Vyberte prosím pouze jednu soutěž',
  [ITranslationKeys.pleaseSelectGame]: 'Vyberte prosím zápas',
  [ITranslationKeys.pleaseSelectTeamAndGoalkeeper]: 'Vyberte prosím tým a brankáře',
  [ITranslationKeys.selectPlayerToCompare]: 'Vybrat hráče k porovnání',
  [ITranslationKeys.selectGoalkeeperToCompare]: 'Vybrat brankáře k porovnání',
  [ITranslationKeys.youCanLoadData]: 'Nyní můžete nahrát data',
};

const translationTables: Pick<ITypeForTranslation, ITranslationKeysTables> = {
  [ITranslationKeys.selectOwnData]: 'Vybrat vlastní data',
  [ITranslationKeys.dataTemplates]: 'Datové šablony',
  [ITranslationKeys.filterPositions]: 'Filtrovat pozice',
  [ITranslationKeys.filterTeam]: 'Filtrovat tým',
  [ITranslationKeys.allPositions]: 'Všechny pozice',
  [ITranslationKeys.onlyForwards]: 'Jen útočníci',
  [ITranslationKeys.onlyDefensemen]: 'Jen obránci',
  [ITranslationKeys.leaguePercentile]: 'Ligový percentil',
  [ITranslationKeys.leagueOrder]: 'Ligové pořadí',
  [ITranslationKeys.leagueAverage]: 'Ligový průměr',
  [ITranslationKeys.displayTimePeriod]: 'Zobrazit období',
  [ITranslationKeys.nGames]: '{{count}} zápasů',
  [ITranslationKeys.months]: 'Měsíce',
  [ITranslationKeys.quarter]: 'Čtvrtiny ZČ',
  [ITranslationKeys.custom]: 'Vlastní',
  [ITranslationKeys.dataSelectionBy]: 'Výběr dat dle',
  [ITranslationKeys.playerMatches]: 'Zápasů hráče',
  [ITranslationKeys.teamMatches]: 'Zápasů týmu',
  [ITranslationKeys.calendar]: 'Kalendáře',
  [ITranslationKeys.addNextTimePeriod]: 'Přidat další období',
  [ITranslationKeys.deleteTimePeriod]: 'Vymazat období',
  [ITranslationKeys.timePeriodByPlayerMatches]: 'Období dle zápasů hráče',
  [ITranslationKeys.timePeriodByTeamMatches]: 'Období dle zápasů týmu',
  [ITranslationKeys.timePeriodByCalendar]: 'Období dle kalendáře',
};

const translationPlayerInfo: Pick<ITypeForTranslation, ITranslationKeysPlayerInfo> = {
  [ITranslationKeys.born]: 'Narozen',
  [ITranslationKeys.age]: 'Věk',
  [ITranslationKeys.height]: 'Výška',
  [ITranslationKeys.weight]: 'Váha',
  [ITranslationKeys.stick]: 'Hůl',
  [ITranslationKeys.years]: 'let',
  [ITranslationKeys.leftStick]: 'Levá',
  [ITranslationKeys.rightStick]: 'Pravá',
  [ITranslationKeys.leftAndRightStick]: 'Levá i pravá',
  [ITranslationKeys.notAvailable]: 'Nedostupné',
  [ITranslationKeys.insufficientMinutes]: 'Nedostatečný počet minut',
  [ITranslationKeys.compareWithOtherPlayer]: 'Porovnat s dalším hráčem',
  [ITranslationKeys.compareWithOtherGoalkeeper]: 'Porovnat s dalším brankářem',
  [ITranslationKeys.downloadPlayerCard]: 'Uložit kartu hráče',
  [ITranslationKeys.downloadGoalkeeperCard]: 'Uložit kartu brankáře',
  [ITranslationKeys.attack]: 'Útok',
  [ITranslationKeys.defend]: 'Obrana',
  [ITranslationKeys.transition]: 'Tranzice',
  [ITranslationKeys.powerPlays]: 'Přesilové hry',
  [ITranslationKeys.goals]: 'Góly',
  [ITranslationKeys.expectedGoals]: 'Očekávané góly',
  [ITranslationKeys.productivity]: 'Produktivita',
  [ITranslationKeys.shotAssists]: 'Přihrávky na střelu',
  [ITranslationKeys.expectedGoalsFromAssists]: 'Očekávané góly z přihrávek',
  [ITranslationKeys.influenceOnScoredTeamGoals]: 'Vliv na vstřelené góly týmu',
  [ITranslationKeys.influenceOnExpectedTeamGoals]: 'Vliv na očekávané góly týmu',
  [ITranslationKeys.enemyBlocksOrShots]: 'Bloky / střely soupeře',
  [ITranslationKeys.avoidedEntryIntoZone]: 'Zamezení vstupů do pásma',
  [ITranslationKeys.bluelineDefending]: 'Průchodnost modré čáry',
  [ITranslationKeys.zoneExitDenials]: 'Zamezení výstupů z pásma',
  [ITranslationKeys.influenceOnSuccessOfFightsAfterDumpins]:
    'Vliv na úspěšnost soubojů po nahození',
  [ITranslationKeys.influenceOnRecivedGoalsOfTeam]: 'Vliv na obdržené góly týmu',
  [ITranslationKeys.influenceOnExpectedGoalsOfEnemy]: 'Vliv na očekávané góly soupeře',
  [ITranslationKeys.controlledZoneEntries]: 'Kontrolované vstupy do pásma',
  [ITranslationKeys.controlledZoneExits]: 'Kontrolované výstupy z pásma',
  [ITranslationKeys.preferenceOfControlledEntries]: 'Preference kontrolovaných vstupů',
  [ITranslationKeys.zoneEntryPasses]: 'Přihrávky na vstupy do pásma',
  [ITranslationKeys.dumpinsWithFight]: 'Nahození se soubojem',
  [ITranslationKeys.transitionsFromDefensiveToOffensiveZone]:
    'Přechody z obranného do útočného pásma',
  [ITranslationKeys.stretchPassZoneExits]: 'Výstupy z pásma dlouhou přihrávkou',
  [ITranslationKeys.slotShotAssists]: 'Přihrávky na střelu ze slotu',
  [ITranslationKeys.dumpouts]: 'Vyhození',
  [ITranslationKeys.defenderShortcut]: 'O',
  [ITranslationKeys.attackerShortcut]: 'Ú',
  [ITranslationKeys.goalkeeperShortcut]: 'B',
};

const translationGames: Pick<ITypeForTranslation, ITranslationKeysGames> = {
  [ITranslationKeysGames.overtimeShortcut]: 'p',
  [ITranslationKeysGames.shootingShortcut]: 'sn',
  [ITranslationKeysGames.danger]: 'nebezpečnost',
  [ITranslationKeysGames.high]: 'Vysoká',
  [ITranslationKeysGames.medium]: 'Střední',
  [ITranslationKeysGames.low]: 'Nízká',
  [ITranslationKeysGames.score]: 'Skóre',
  [ITranslationKeysGames.lineup]: 'Sestava',
  [ITranslationKeysGames.teamShots]: 'Střely týmu',
  [ITranslationKeysGames.shotsAgainst]: 'Střely soupeře',
  [ITranslationKeysGames.negative]: 'Negativní',
  [ITranslationKeysGames.positive]: 'Pozitivní',
  [ITranslationKeysGames.shotsHeatmap]: 'Heatmapa střel',
  [ITranslationKeysGames.winExpectation]: 'Očekávanost výhry',
  [ITranslationKeysGames.defenseFirstLetter]: 'O',
  [ITranslationKeysGames.forwardFirstLetter]: 'Ú',
  [ITranslationKeysGames.stickLetter]: 'H',
  [ITranslationKeysGames.leftLetter]: 'L',
  [ITranslationKeysGames.rightLetter]: 'P',
  [ITranslationKeysGames.allShotAttemptsFromSlot]: 'Střelecké pokusy ze slotu',
  [ITranslationKeysGames.allPlayers]: 'Všichni hráči',
  [ITranslationKeysGames.shotsOnGoal]: 'Střely na branku',
  [ITranslationKeysGames.playGoals]: 'Přehrát góly',
  [ITranslationKeysGames.playAllGoals]: 'Přehrát všechny góly',
  [ITranslationKeysGames.gameReport]: 'Zápasový report',
  [ITranslationKeysGames.statsInGame5on5]: 'Statistiky ve hře 5 na 5',
  [ITranslationKeysGames.statsInPowerPlay]: 'Statistiky v přesilových hrách',
  [ITranslationKeysGames.showAllPlayers]: 'Zobrazit všechny hráče',
  [ITranslationKeysGames.noGamesToDisplay]: 'Žádné zápasy k zobrazení',
  [ITranslationKeysGames.shootingsSuperiority]: 'Střelecká převaha',
};

const translationVideomaps: Pick<ITypeForTranslation, ITranslationKeysVideomaps> = {
  [ITranslationKeysVideomaps.count]: 'Počet',
  [ITranslationKeysVideomaps.shotsDanger]: 'Nebezpečnost střel',
  [ITranslationKeysVideomaps.entryDanger]: 'Nebezpečnost vstupu',
  [ITranslationKeysVideomaps.exitDanger]: 'Nebezpečnost výstupu',
  [ITranslationKeysVideomaps.last5Games]: 'Posledních 5 zápasů',
  [ITranslationKeysVideomaps.withTeammate]: 'Hráči spolu',
  [ITranslationKeysVideomaps.filterBy]: 'Zobrazit ke srovnání',
  [ITranslationKeysVideomaps.play]: 'Přehrát video',
  [ITranslationKeysVideomaps.saveMap]: 'Uložit mapu',
  [ITranslationKeysVideomaps.saveMaps]: 'Uložit mapy',
  [ITranslationKeysVideomaps.hide]: 'Skrýt',
  [ITranslationKeysVideomaps.total]: 'Celkem',
  [ITranslationKeysVideomaps.totalValuesPerTeam]: 'Celkové hodnoty za tým',
  [ITranslationKeysVideomaps.left]: 'Vlevo',
  [ITranslationKeysVideomaps.center]: 'Středem',
  [ITranslationKeysVideomaps.right]: 'Vpravo',
  [ITranslationKeysVideomaps.playSelectedMatches]: 'Přehrát vybrané zápasy',
  [ITranslationKeysVideomaps.selectAllGames]: 'Vybrat všechny zápasy',
  [ITranslationKeysVideomaps.deselectAllGames]: 'Odebrat všechny zápasy',
  [ITranslationKeysVideomaps.allShotAttempts]: 'Střelecké pokusy',
  [ITranslationKeysVideomaps.onNet]: 'Na branku',
  [ITranslationKeysVideomaps.inSlot]: 'Ve slotu',
  [ITranslationKeysVideomaps.slot]: 'Slot',
  [ITranslationKeysVideomaps.innerSlot]: 'Vnitřní slot',
  [ITranslationKeysVideomaps.oneTimer]: 'Z první',
  [ITranslationKeysVideomaps.rebound]: 'Dorážka',
  [ITranslationKeysVideomaps.rebounds]: 'Dorážky',
  [ITranslationKeysVideomaps.forecheck]: 'Forček',
  [ITranslationKeysVideomaps.rush]: 'Rychlý útok',
  [ITranslationKeysVideomaps.cycle]: 'Dlouhý útok',
  [ITranslationKeysVideomaps.oddManRush]: 'Přečíslení',
  [ITranslationKeysVideomaps.fromFaceoff]: 'Po vhazování',
  [ITranslationKeysVideomaps.whoShoots]: 'Kdo střílel',
  [ITranslationKeysVideomaps.selectAtLeastTwoPlayers]: 'Vyberte alespoň 2 hráče, prosím.',
  [ITranslationKeysVideomaps.shotCategory]: 'Kategorie střel',
  [ITranslationKeysVideomaps.shotLocation]: 'Lokace střel',
  [ITranslationKeysVideomaps.shotTypes]: 'Typy střel',
  [ITranslationKeysVideomaps.attackType]: 'Typy akce',
  [ITranslationKeysVideomaps.timeRecalculatedValues]: 'Časově přepočtené hodnoty',
  [ITranslationKeysVideomaps.equityAndDerivedValues]: 'Podílové a odvozené hodnoty',
  [ITranslationKeysVideomaps.relativeToTeam]: 'Relativní ke zbytku týmu',
  [ITranslationKeysVideomaps.trajectories]: 'Trajektorie',
  [ITranslationKeysVideomaps.showAllTrajectories]: 'Vykreslit všechny trajektorie',
  [ITranslationKeysVideomaps.whoPasses]: 'Kdo přihrával',
  [ITranslationKeysVideomaps.passCategory]: 'Kategorie střel z přihrávek',
  [ITranslationKeysVideomaps.shotAfterPassLocation]: 'Lokace následné střely',
  [ITranslationKeysVideomaps.passTypes]: 'Typy přihrávek',
  [ITranslationKeysVideomaps.behindTheNet]: 'Zpoza branky',
  [ITranslationKeysVideomaps.crossIce]: 'Křižné',
  [ITranslationKeysVideomaps.passOneTimer]: 'Na střelu z první',
  [ITranslationKeysVideomaps.compareWithLeague]: 'Porovnat s průměrem TELH',
  [ITranslationKeysVideomaps.shotsProduction]: 'Produkce střel',
  [ITranslationKeysVideomaps.useOfOpportunities]: 'Proměňování šancí',
  [ITranslationKeysVideomaps.withoutComparison]: 'Bez srovnání',
  [ITranslationKeysVideomaps.loadHeatmap]: 'Nahrát heatmapu',
  [ITranslationKeysVideomaps.onlyOnePlayerRequired]:
    'Pro heatmapu střel samotného hráče je nutné, aby byl vybrán pouze jeden hráč.',
  [ITranslationKeysVideomaps.faceoffResult]: 'Výsledek vhazování',
  [ITranslationKeysVideomaps.faceoffsFollowedBy]: 'Zakončení po vhazování',
  [ITranslationKeysVideomaps.won]: 'Vyhraná',
  [ITranslationKeysVideomaps.lost]: 'Prohraná',
  [ITranslationKeysVideomaps.shotAttempt]: 'Střela',
  [ITranslationKeysVideomaps.shotFromSlot]: 'Střela ze slotu',
  [ITranslationKeysVideomaps.goal]: 'Gól',
  [ITranslationKeysVideomaps.allFaceoffs]: 'Všechna vhazování',
  [ITranslationKeysVideomaps.leftSideFaceoffs]: 'Vhazování na levé straně',
  [ITranslationKeysVideomaps.rightSideFaceoffs]: 'Vhazování na pravé straně',
  [ITranslationKeysVideomaps.puckGainType]: 'Typ zisku puku',
  [ITranslationKeysVideomaps.entryType]: 'Typ vstupu',
  [ITranslationKeysVideomaps.exitType]: 'Typ výstupu',
  [ITranslationKeysVideomaps.enterExitLocation]: 'Lokace vstupu/výstupu',
  [ITranslationKeysVideomaps.captureOrBlock]: 'Zachycením/blokem',
  [ITranslationKeysVideomaps.inFight]: 'V souboji',
  [ITranslationKeysVideomaps.carryIn]: 'Zavezením',
  [ITranslationKeysVideomaps.withPass]: 'Přihrávkou',
  [ITranslationKeysVideomaps.carryOut]: 'Vyvezením',
  [ITranslationKeysVideomaps.passBehindRedLine]: 'Přihrávkou za červenou čárou',
  [ITranslationKeysVideomaps.middle]: 'Uprostřed',
  [ITranslationKeysVideomaps.entryExitType]: 'Typ vstupu/výstupu',
  [ITranslationKeysVideomaps.puckGain]: 'Zisk puku',
  [ITranslationKeysVideomaps.dumpins]: 'Nahození',
  [ITranslationKeysVideomaps.shifts]: 'Střídání',
  [ITranslationKeysVideomaps.dumpout]: 'Vyhození',
  [ITranslationKeysVideomaps.successfully]: 'Úspěšné',
  [ITranslationKeysVideomaps.unsuccessfully]: 'Neúspěšné',
  [ITranslationKeysVideomaps.offensiveZoneGain]: 'Zisk útočného pásma',
  [ITranslationKeysVideomaps.losingPuckInMiddleZone]: 'Ztráta puku ve strědním pásmu',
  [ITranslationKeysVideomaps.entryCategory]: 'Kategorie vstupu',
  [ITranslationKeysVideomaps.bySuccess]: 'Podle úspěšnosti',
  [ITranslationKeysVideomaps.finish]: 'Zakončení',
  [ITranslationKeysVideomaps.whoExitsFromDefensiveZone]: 'Kdo vystupoval z obranného pásma',
  [ITranslationKeysVideomaps.whoEntersToOffensiveZone]: 'Kdo vstupoval do pásma',
  [ITranslationKeysVideomaps.exitCategory]: 'Kategorie výstupu',
  [ITranslationKeysVideomaps.exitZoneResult]: 'Výsledek výstupu z pásma',
  [ITranslationKeysVideomaps.dumpoutResult]: 'Výsledek vyhození',
  [ITranslationKeysVideomaps.dumpinResult]: 'Výsledek nahození',
  [ITranslationKeysVideomaps.dumpoutDanger]: 'Nebezpečnost vyhození',
  [ITranslationKeysVideomaps.fightForPuck]: 'Souboj o puk',
  [ITranslationKeysVideomaps.puckGainOfTheAttackingTeam]: 'Zisk útočícího týmu',
  [ITranslationKeysVideomaps.puckGainOfTheDefendingTeam]: 'Zisk bránícího týmu',
  [ITranslationKeysVideomaps.teamRanking]: 'Pořadí v týmu',
  [ITranslationKeysVideomaps.telhRanking]: 'Pořadí v TELH',
  [ITranslationKeysVideomaps.blocked]: 'Zblokované',
  [ITranslationKeysVideomaps.missedShots]: 'Střely mimo branku',
  [ITranslationKeysVideomaps.posts]: 'Střely do tyče',
};

const translationAccountManagement: Pick<ITypeForTranslation, ITranslationKeysAccountManagement> = {
  [ITranslationKeysAccountManagement.changeOfPassword]: 'Změna hesla',
  [ITranslationKeysAccountManagement.hokejPlayerManagement]: 'Správa hokej.PLAYER',
  [ITranslationKeysAccountManagement.downloadsManagement]: 'Správa stahování',
  [ITranslationKeysAccountManagement.newPassword]: 'Nové heslo',
  [ITranslationKeysAccountManagement.newPasswordAgain]: 'Nové heslo znovu',
  [ITranslationKeysAccountManagement.changePassword]: 'Změnit heslo',
  [ITranslationKeysAccountManagement.downloadVideo]: 'Stáhnout video',
  [ITranslationKeysAccountManagement.deleteVideo]: 'Odstranit video',
  [ITranslationKeysAccountManagement.multipleFiles]: 'více souborů',
  [ITranslationKeysAccountManagement.category]: 'Kategorie',
  [ITranslationKeysAccountManagement.match]: 'Zápas',
  [ITranslationKeysAccountManagement.time]: 'Čas',
  [ITranslationKeysAccountManagement.downloadAll]: 'Stáhnout vše',
  [ITranslationKeysAccountManagement.deleteAll]: 'Odstranit vše',
  [ITranslationKeysAccountManagement.previous]: 'Předchozí',
  [ITranslationKeysAccountManagement.next]: 'Následující',
  [ITranslationKeysAccountManagement.enterEmail]: 'Zadejte e-mail',
  [ITranslationKeysAccountManagement.sentClips]: 'Odeslané klipy',
  [ITranslationKeysAccountManagement.playerPosition]: 'Post',
  [ITranslationKeysAccountManagement.email]: 'E-mail',
  [ITranslationKeysAccountManagement.videocoachClips]: 'Klipy videokouče',
  [ITranslationKeysAccountManagement.passwordsDontMatch]: 'Zadaná hesla nejsou stejná.',
  [ITranslationKeysAccountManagement.passwordTooShort]: 'Heslo musí mít minimálně 6 znaků.',
  [ITranslationKeysAccountManagement.passwordChanged]: 'Heslo bylo úspěšně změněno.',
  [ITranslationKeysAccountManagement.passwordChangedLoginAgain]:
    'Heslo bylo změněno, prosím, přihlašte se znovu.',
  [ITranslationKeysAccountManagement.language]: 'Jazyk',
  [ITranslationKeysAccountManagement.emailEdited]: 'E-mail byl úspěšně změněn.',
  [ITranslationKeysAccountManagement.emailDeleted]: 'E-mail byl úspěšně smazán.',
  [ITranslationKeysAccountManagement.enterValidEmail]: 'Prosím, zadejte správný e-mail.',
  [ITranslationKeysAccountManagement.languageChanged]: 'Jazyk byl úspěšně změněn.',
  [ITranslationKeysAccountManagement.clipDeleteError]: 'Klip se nepodařilo odstranit.',
  [ITranslationKeysAccountManagement.clipDeleted]: 'Klip byl úspěšně odstraněn.',
};

const translationGoalkeeperInfo: Pick<ITypeForTranslation, ITranslationKeysGoalkeeperInfo> = {
  [ITranslationKeys.seasonStatistics]: 'Statistiky sezóny',
  [ITranslationKeys.successOfInterventions]: 'Úspěšnost zákroků',
  [ITranslationKeys.receivedGoals]: 'Obdržené góly',
  [ITranslationKeys.actualForm]: 'Aktuální forma',
  [ITranslationKeys.goalsSavedAboveExpectation]: 'Góly chycené nad očekávání',
  [ITranslationKeys.stolenGames]: 'Vychytané zápasy',
  [ITranslationKeys.shutouts]: 'Čistá konta',
  [ITranslationKeys.goalieReliability]: 'Spolehlivost',
  [ITranslationKeys.controlledSaves]: 'Kontrolované zákroky',
  [ITranslationKeys.saves]: 'Zákroky',
  [ITranslationKeys.opponentsStick]: 'Držení hole soupeře',
  [ITranslationKeys.controlled]: 'Kontrolované',
  [ITranslationKeys.uncontrolled]: 'Vyražené do slotu',
  [ITranslationKeys.screeningPlayer]: 'Zacloněné',
  [ITranslationKeys.deflected]: 'Teče',
  [ITranslationKeys.afterCrossIceShotAssists]: 'Střely po křižných přihrávkách',
  [ITranslationKeys.shootoutAttempts]: 'Samostatné nájezdy',
  [ITranslationKeys.moreGoalies]: 'Více brankářů ke srovnání',
  [ITranslationKeys.noGoalkeeperDataMessage]:
    'K tomuto brankáři na základě vybraných filtrů neexistují žádná data.',
  [ITranslationKeys.noDataForSelected]: 'Pro vybrané bohužel neexistují žádná data',
};

const translationShootout: Pick<ITypeForTranslation, ITranslationKeysShootout> = {
  [ITranslationKeysShootout.allAttempts]: 'Všechny pokusy',
  [ITranslationKeysShootout.shot]: 'Střela',
  [ITranslationKeysShootout.feint]: 'Klička',
  [ITranslationKeysShootout.forehand]: 'Forehand',
  [ITranslationKeysShootout.backhand]: 'Backhand',
  [ITranslationKeysShootout.sideOfEnding]: 'Strana zakončení',
  [ITranslationKeysShootout.typeOfEnding]: 'Typ zakončení',
  [ITranslationKeysShootout.resultOfEnding]: 'Výsledek zakončení',
  [ITranslationKeysShootout.goalkeepersShootouts]: 'Nájezdy na brankáře',
};

const translationAllWordNominativ: Pick<ITypeForTranslation, ITranslationKeysAllWordNominativ> = {
  [ITranslationKeysAllWordNominativ.all]: 'Všechna',
  [ITranslationKeysAllWordNominativ.all_male]: 'Všichni',
  [ITranslationKeysAllWordNominativ.all_female]: 'Všechny',
  [ITranslationKeysAllWordNominativ.all_one]: 'Všechno',
  [ITranslationKeysAllWordNominativ.all_male_one]: 'Všechen',
  [ITranslationKeysAllWordNominativ.all_female_one]: 'Všechna',
};

const translationPeriods: Pick<ITypeForTranslation, ITranslationKeysPeriods> = {
  [ITranslationKeysPeriods.period]: 'Třetina',
  [ITranslationKeysPeriods.periodWithOrder]: '{{count}}. třetina',
};

const translationNetZones: Pick<ITypeForTranslation, ITranslationKeysNetZones> = {
  [ITranslationKeysNetZones.ur]: 'Vpravo nahoře',
  [ITranslationKeysNetZones.ce]: 'Střed',
  [ITranslationKeysNetZones.ul]: 'Vlevo nahoře',
  [ITranslationKeysNetZones.br]: 'Vpravo dole',
  [ITranslationKeysNetZones.fh]: 'Mezi betony',
  [ITranslationKeysNetZones.bl]: 'Vlevo dole',
};

const translationVideoCenter: Pick<ITypeForTranslation, ITranslationKeysVideoCenter> = {
  [ITranslationKeysVideoCenter.downloadSelectedVideos]: 'Stáhnout vybraná videa',
  [ITranslationKeysVideoCenter.videoCenter]: 'Centrum videa',
  [ITranslationKeysVideoCenter.cutBefore]: 'Ořez před',
  [ITranslationKeysVideoCenter.cutAfter]: 'Ořez po',
  [ITranslationKeysVideoCenter.videoTime]: 'Čas videa',
  [ITranslationKeysVideoCenter.videocoachNotes]: 'Poznámky videocoache',
  [ITranslationKeysVideoCenter.clipName]: 'Název klipu',
  [ITranslationKeysVideoCenter.actionRating]: 'Hodnocení akce',
  [ITranslationKeysVideoCenter.situationNote]: 'Poznámka k herní situaci',
  [ITranslationKeysVideoCenter.noteReceivers]: 'Příjemci poznámky',
  [ITranslationKeysVideoCenter.forwards]: 'Útočníci',
  [ITranslationKeysVideoCenter.defensemen]: 'Obránci',
  [ITranslationKeysVideoCenter.sendToPlayers]: 'Odeslat hráči / hráčům',
  [ITranslationKeysVideoCenter.downloadingVideos]: 'Začíná stahování videí.',
  [ITranslationKeysVideoCenter.downloadingVideosError]: 'Stahování videí nebylo možné dokončit.',
  [ITranslationKeysVideoCenter.downloadingVideosSuccesful]:
    'Stahování videí proběhlo úspěšně, naleznete je ve správě stahování.',
  [ITranslationKeysVideoCenter.clipNameEmpty]: 'Název klipu musí být vyplněn.',
  [ITranslationKeysVideoCenter.zeroPlayersSelected]: 'Musíte vybrat alespoň jednoho hráče.',
  [ITranslationKeysVideoCenter.noteSend]: 'Poznámka úspěšně odeslána.',
  [ITranslationKeysVideoCenter.noteNotSend]: 'Při odesílání poznámky nastala chyba.',
  [ITranslationKeysVideoCenter.navigateToVideomap]: 'Přejít na videomapu',
  [ITranslationKeysVideoCenter.videoLinks]: 'Odkazy na video',
  [ITranslationKeysVideoCenter.videoLoadFailed]: 'Načítání videa selhalo.',
};

const translationTopPlayers: Pick<ITypeForTranslation, ITranslationKeysTopPlayers> = {
  [ITranslationKeysTopPlayers.topPlayers]: 'Top hráči',
  [ITranslationKeysTopPlayers.topPlayers_one]: 'Top hráč',
  [ITranslationKeysTopPlayers.topPlayers_two]: 'Top {{count}} hráči',
  [ITranslationKeysTopPlayers.topPlayers_few]: 'Top {{count}} hráči',
  [ITranslationKeysTopPlayers.topPlayers_other]: 'Top {{count}} hráčů',
};

const translationTracking: Pick<ITypeForTranslation, ITranslationKeysTracking> = {
  [ITranslationKeysTracking.backToApp]: 'Zpět do aplikace',
  [ITranslationKeysTracking.analyticsOverview]: 'Přehled analytik',
  [ITranslationKeysTracking.users]: 'Uživatelé',
  [ITranslationKeysTracking.logins]: 'Přihlašování',
  [ITranslationKeysTracking.eventLog]: 'Log událostí',
  [ITranslationKeysTracking.admin]: 'Admin',
  [ITranslationKeysTracking.media]: 'Média',
  [ITranslationKeysTracking.user]: 'Uživatel',
  [ITranslationKeysTracking.client]: 'Klient',
  [ITranslationKeysTracking.userRole]: 'Uživatelská role',
  [ITranslationKeysTracking.analyticsType]: 'Typ analytiky',
  [ITranslationKeysTracking.interval]: 'Časový úsek',
  [ITranslationKeysTracking.displayData]: 'Zobrazit data',
  [ITranslationKeysTracking.activeUser]: 'Aktivní uživatel',
  [ITranslationKeysTracking.teamMedia]: 'Tým / Medium',
  [ITranslationKeysTracking.pleaseSelectTeamOrMedia]: 'Vyberte prosím tým nebo media',
  [ITranslationKeysTracking.visits]: 'Návštěvy',
  [ITranslationKeysTracking.uniqueUsers]: 'Unikátní uživatelé',
  [ITranslationKeysTracking.openScreensCount]: 'Počty otevřených obrazovek',
  [ITranslationKeysTracking.screens]: 'Obrazovky',
  [ITranslationKeysTracking.allUsers]: 'Všichni uživatelé',
  [ITranslationKeysTracking.pickUser]: 'Vybrat uživatele',
  [ITranslationKeysTracking.roleChanged]: 'Role byla úspěšně změněna.',
  [ITranslationKeysTracking.videocoachChanged]: 'Tým videocoache úspěšně změněn.',
  [ITranslationKeysTracking.removeVideocoachNote]:
    'Opravdu chcete odstranit tuto poznámku videocoache?',
  [ITranslationKeysTracking.userActivated]: 'Uživatel byl úspěšně aktivován.',
  [ITranslationKeysTracking.userDeactivated]: 'Uživatel byl úspěšně deaktivován.',
  [ITranslationKeysTracking.changeInProgress]: 'Čekejte, prosím, změna probíhá.',
  [ITranslationKeysTracking.openedPage]: 'Otevřená stránka',
  [ITranslationKeysTracking.event]: 'Událost',
  [ITranslationKeysTracking.lastLogin]: 'Poslední přihlášení',
  [ITranslationKeysTracking.loginCountTotalAndLast30Days]: 'Počet přihlášení celkem + za 30 dní',
  [ITranslationKeysTracking.loginList]: 'Seznam přihlášení',
  [ITranslationKeysTracking.registeredPlayers]: 'Přihlášených hráčů',
  [ITranslationKeysTracking.sentLinks]: 'Odeslaných linků',
  [ITranslationKeysTracking.clickthroughRate]: 'Procento prokliků',
  [ITranslationKeysTracking.playersWithAttendance]: 'Hráči s návštěvností',
  [ITranslationKeysTracking.totalPlayersWithSavedClips]: 'Celkem hráčů s uloženými klipy',
  [ITranslationKeysTracking.totalNotesSent]: 'Celkem odeslaných poznámek',
  [ITranslationKeysTracking.videocoachNotesSent]: 'Zaslaných poznámek videokouče',
  [ITranslationKeysTracking.numberOfClipsInPlaylist]: 'Počet klipů v playlistu',
  [ITranslationKeysTracking.searchPlayer]: 'Vyhledat hráče',
  [ITranslationKeysTracking.teamSelection]: 'Výběr týmu',
  [ITranslationKeysTracking.attendance]: 'Návštěvnost',
  [ITranslationKeysTracking.dataByTimePeriod]: 'Data za období',
  [ITranslationKeysTracking.higherThan]: 'Vyšší než {{context}}',
  [ITranslationKeysTracking.equalTo]: 'Rovno {{context}}',
  [ITranslationKeysTracking.doesNotMatter]: 'Nerozhoduje',
  [ITranslationKeysTracking.tracking]: 'Tracking',
  [ITranslationKeysTracking.overview]: 'Přehled',
  [ITranslationKeysTracking.inputSearchedEmail]: 'Zadejte hledaný email',
  [ITranslationKeysTracking.downloadUsersList]: 'Stáhnout seznam uživatelů',
  [ITranslationKeysTracking.dateAndTime]: 'Datum a čas',
  [ITranslationKeysTracking.ipAdress]: 'IP adresa',
  [ITranslationKeysTracking.eventType]: 'Typ události',
  [ITranslationKeysTracking.allEvents]: 'Všechny události',
};

const translationCms: Pick<ITypeForTranslation, ITranslationKeysCms> = {
  [ITranslationKeysCms.helpManagement]: 'Správa nápovědy',
  [ITranslationKeysCms.metricsManagement]: 'Správa metrik',
  [ITranslationKeysCms.templatesManagement]: 'Správa šablon',
  [ITranslationKeysCms.definedTemplatesManagement]: 'Předchystané šablony',
  [ITranslationKeysCms.userManagement]: 'Správa uživatelů',
  [ITranslationKeysCms.notFilledOut]: 'Nevyplněno',
  [ITranslationKeysCms.addUser]: 'Přidat uživatele',
  [ITranslationKeysCms.userAdded]: 'Uživatel byl úspěšně přidán.',
  [ITranslationKeysCms.helpContentEditation]: 'Editace obsahu nápovědy',
  [ITranslationKeysCms.editFunctions]: 'Editovat funkce',
  [ITranslationKeysCms.editTips]: 'Editovat tipy',
  [ITranslationKeysCms.editNews]: 'Editovat novinky',
  [ITranslationKeysCms.editOpeningText]: 'Editovat úvodní text',
  [ITranslationKeysCms.caption]: 'Titulek',
  [ITranslationKeysCms.addAnotherItem]: 'Přidat další položku',
  [ITranslationKeysCms.textEditation]: 'Úprava textu',
  [ITranslationKeysCms.itemEditation]: 'Editace položky',
  [ITranslationKeysCms.sectionEditation]: 'Úprava sekce',
};

const translationEcContent: Pick<ITypeForTranslation, ITranslationKeysEcContent> = {
  [ITranslationKeysEcContent.formationsCompare]: 'Srovnání formací',
  [ITranslationKeysEcContent.iceTime]: 'IceTime',
  [ITranslationKeysEcContent.momentum]: 'Vývoj zápasu',
};

const translationBugReport: Pick<ITypeForTranslation, ITranslationKeysBugReport> = {
  [ITranslationKeysBugReport.reportBug]: 'Nahlásit chybu',
  [ITranslationKeysBugReport.metricsInaccuracy]: 'Nepřesnost v metrikách',
  [ITranslationKeysBugReport.featureNotWorking]: 'Funkce nefunguje',
  [ITranslationKeysBugReport.otherProblem]: 'Jiný problém',
  [ITranslationKeysBugReport.describeProblem]: 'Řekněte nám více o nahlašované chybě.',
  [ITranslationKeysBugReport.send]: 'Odeslat',
  [ITranslationKeysBugReport.wrongTime]: 'Video se spouští ve špatný čas',
  [ITranslationKeysBugReport.wrongPlayer]: 'Chybně identifikovaný hráč',
  [ITranslationKeysBugReport.wrongEvent]: 'Chybná událost',
  [ITranslationKeysBugReport.reportSent]: 'Hlášení úspěšně odesláno.',
  [ITranslationKeysBugReport.reportSentError]: 'Při odesílání hlášení došlo k chybě.',
};

export const TRANSLATION_CZ: ITypeForTranslation = {
  ...translationMetrics,
  ...translationBase,
  ...translationSeasonParts,
  ...translationCountOfPlayers,
  ...translationHomeOrAway,
  ...translationGameState,
  ...translationErrors,
  ...translationNavigation,
  ...translationModal,
  ...translationWidgets,
  ...translationWidgetsDescriptions,
  ...translationFilters,
  ...translationPagesWithCards,
  ...translationTables,
  ...translationPlayerInfo,
  ...translationGames,
  ...translationGoalkeeperInfo,
  ...translationAccountManagement,
  ...translationVideomaps,
  ...translationShootout,
  ...translationAllWordNominativ,
  ...translationPeriods,
  ...translationNetZones,
  ...translationVideoCenter,
  ...translationTopPlayers,
  ...translationTracking,
  ...translationCms,
  ...translationEcContent,
  ...translationCSVExports,
  ...translationBugReport,
};
